/* eslint-disable import/no-extraneous-dependencies */
import React, { ReactNode, useState } from 'react';
import App from 'next/app';
import '../styles/globals.css';
import '../styles/PinCode.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/how-it-work.css';
import 'public/constants/styles.css';
import '../styles/arc.css';
import {
  getStorage,
  resetAccountData,
  setStorage,
  STORAGE_KEYS
} from '../utils/storage';
import { CARDS, CONNECTION } from '../constants/common';
import AuthGuard from '../contexts/AuthGuard';
import { AuthProvider } from '../contexts/AuthContext';

interface AppContextValue {
  account: string;
  phone: string;
  email: string;
  userName: string;
  selectedCard: string;
  connection: string;
  loading: boolean;
  resetData: () => void;
  onSaveData: (value: string, key: string) => void;
  onSaveMultiData: (values: string[], key: string[]) => void;
}

interface AppState {
  account: string;
  phone: string;
  email: string;
  userName: string;
  selectedCard: string;
  connection: string;
  loading: boolean;
  resetData: () => void;
  onSaveData: (value: string, key: string) => void;
  onSaveMultiData: (value: string[], key: string[]) => void;
}

export const AppContext = React.createContext<AppContextValue>(null);

export const MainLayout = ({ children }: { children: ReactNode }) => {
  const connection = getStorage(STORAGE_KEYS.CONNECTION);
  const email = getStorage(STORAGE_KEYS.EMAIL);
  const phone = getStorage(STORAGE_KEYS.PHONE);
  const [data, setData] = useState({
    account:
      getStorage(STORAGE_KEYS.ACCOUNT) ||
      (connection === CONNECTION.EMAIL ? email : phone),
    phone,
    email,
    userName: getStorage(STORAGE_KEYS.USER_NAME),
    selectedCard: getStorage(STORAGE_KEYS.SELECTED_CARD) || CARDS[1].value,
    connection: connection,
    loading: false
  });

  const onSaveData = (value, key) => {
    setData({
      ...data,
      [key]: value
    });
    setStorage(key, value);
  };

  const resetData = () => {
    setData({
      account: '',
      phone: '',
      email: '',
      userName: '',
      selectedCard: CARDS[1].value,
      connection: '',
      loading: false
    });
    resetAccountData();
  };

  const onSaveMultiData = (values, keys) => {
    values.forEach((value, index) => {
      setData({
        ...data,
        [keys[index]]: value
      });
      setStorage(keys[index], value);
    });
  };
  return (
    <AppContext.Provider
      value={{
        ...data,
        onSaveData: onSaveData,
        onSaveMultiData: onSaveMultiData,
        resetData: resetData
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default class MyApp extends App<any, any, AppState> {
  render() {
    const { Component, pageProps } = this.props;

    return (
      <AuthProvider>
        <AuthGuard>
          <MainLayout>
            <Component {...pageProps} />
          </MainLayout>
        </AuthGuard>
      </AuthProvider>
    );
  }
}
