import React, { Suspense } from 'react';
import useAuth from '../hocs/useAuth';
import { ROUTES } from '../constants/routes';
import { ERROR_MESSAGES } from '../constants/error';
import { SplashScreen } from '../components/SplashScreen';

interface AuthGuardProps {
  children?: any;
}

interface RedirectPageProps {
  children: any;
  error?: string;
}

import { useRouter } from 'next/router';
import { isBrowser } from '../utils/helper';
import { getStorage, STORAGE_KEYS } from '../utils/storage';

const RedirectPage = ({ children, error }: RedirectPageProps) => {
  const router = useRouter();
  // Make sure we're in the browser
  if (
    isBrowser &&
    error &&
    router.pathname.indexOf(ROUTES.REFERRAL_PAGE) > -1
  ) {
    router.push(ROUTES.HOME);
    return <SplashScreen />;
  }

  return children;
};

const AuthGuard = ({ children }: AuthGuardProps) => {
  const { isAuthenticated } = useAuth();
  const userMetadata = getStorage(STORAGE_KEYS.USER_METADATA);

  return (
    <Suspense fallback={<SplashScreen />}>
      {userMetadata || isAuthenticated ? (
        <>{children}</>
      ) : (
        <RedirectPage error={ERROR_MESSAGES.INVALID_AUTH}>
          {children}
        </RedirectPage>
      )}
    </Suspense>
  );
};

export default AuthGuard;
