export const API_ROUTES = {
  SIGN_IN: '/auth/signup',
  VERIFY_OTP: '/auth/verify-waitlist',
  REFRESH_TOKEN: '/auth/refresh-token',
  CREATE_AUTH_CONNECTION: '/auth/connection',
  AUTH_ACCOUNT: '/auth/account',
  LINK_ACCOUNT: '/auth/link-connection',
  REFERRAL_REWARDS: '/referrals/reward',
  SEND_SMS: '/send-sms',
  FDIC_INSURANCE: '/fdic-insurance',
  CONTACT_US: '/contact-us',
  JOIN_WAITLIST: '/website/join-waitlist'
};
