import { LEGAL_STYLES } from 'public/constants/styles-constants';
import { SITE_DATA } from 'public/constants/common';

export const DOGEKING_EVOLVE_BANK_PRIVACY = [
  {
    className: '',
    text: 'What does Evolve Bank & Trust do with your Personal Information?'
  },
  {
    className: 'text-center',
    text: 'Policies'
  },
  {
    className: '',
    text: `<div class="overflow-hidden">
    <table class="table-fixed pt-2 ${LEGAL_STYLES.textContent}">
      <tbody>
        <tr>
          <td class="border w-20 py-8 font-medium text-center">WHY?</td>
          <td class="border px-6 py-8 font-medium">
            Financial companies choose how they share your
            personal information. Federal law gives consumers the
            right to limit some but not all sharing. Federal law
            also requires us to tell you how we collect, share,
            and protect your personal information. Please read
            this notice carefully to understand what we do.
          </td>
        </tr>
        <tr class="bg-light-blue-200">
          <td class="border w-20 py-8 font-medium text-center">WHAT?</td>
          <td class="border px-6 py-8 font-medium">
            The types of personal information we collect and share
            depend on the product or service you have with us.
            This information can include:<p>• Social Security number
            and account transactions</p> <p>• Payment history and account
            balances</p> <p>• Credit history and transaction or loss
            history</p>
          </td>
        </tr>
        <tr>
          <td class="border w-20 py-8 font-medium text-center">HOW?</td>
          <td class="border px-6 py-8 font-medium">
            All financial companies need to share members’
            personal information to run their everyday business.
            In the section below, we list the reasons financial
            companies can share their customers’ personal
            information; the reasons Evolve Bank & Trust chooses
            to share; and whether you can limit this sharing.
          </td>
        </tr>
      </tbody>
    </table>
  </div>`
  },
  {
    className: 'text-center',
    text: 'Sharing Your Information'
  },
  {
    className: '',
    text: `<div class="overflow-hidden">
    <table class="table-fixed pt-10 ${LEGAL_STYLES.textContent}">
      <tbody>
        <tr>
          <td class="border px-4 py-8 font-medium">
            REASONS WE CAN SHARE YOUR PERSONAL INFORMATION
          </td>
          <td class="border px-4 py-8 font-medium text-center">
            DOES EVOLVE BANK & TRUST SHARE?
          </td>
          <td class="border px-4 py-8 font-medium text-center">
            CAN YOU LIMIT THIS SHARING?
          </td>
        </tr>
        <tr>
        <td class="border px-4 py-8 font-medium">
          For our everyday business purposes such as to process
          your transactions, maintain your account(s), respond to
          court orders and legal investigations, or report to credit
          bureaus
        </td>
        <td class="border px-4 py-8 font-medium text-center">
          YES
        </td>
        <td class="border px-4 py-8 font-medium text-center">
         NO
        </td>
      </tr>
      <tr>
        <td class="border px-4 py-8 font-medium">
        For our marketing purposes to offer our products and services
        to you
        </td>
        <td class="border px-4 py-8 font-medium text-center">
          YES
        </td>
        <td class="border px-4 py-8 font-medium text-center">
         NO
        </td>
      </tr>
      <tr>
        <td class="border px-4 py-8 font-medium">
          For joint marketing with other financial companies
        </td>
        <td class="border px-4 py-8 font-medium text-center">
          YES
        </td>
        <td class="border px-4 py-8 font-medium text-center">
         NO
        </td>
      </tr>
      <tr>
        <td class="border px-4 py-8 font-medium">
        For our affiliates’ everyday business purposes information
         about your transactions and experiences
        </td>
        <td class="border px-4 py-8 font-medium text-center">
          YES
        </td>
        <td class="border px-4 py-8 font-medium text-center">
         NO
        </td>
      </tr>
      <tr>
        <td class="border px-4 py-8 font-medium">
        For our affiliates’ everyday business purposes information about
        your creditworthiness
        </td>
        <td class="border px-4 py-8 font-medium text-center">
          NO
        </td>
        <td class="border px-4 py-8 font-medium text-center">
        WE DON’T SHARE
        </td>
      </tr>
      <tr>
      <td class="border px-4 py-8 font-medium">
      For our affiliates to market to you
      </td>
      <td class="border px-4 py-8 font-medium text-center">
      NO
      </td>
      <td class="border px-4 py-8 font-medium text-center">
      WE DON’T SHARE
      </td>
    </tr>
    <tr>
    <td class="border px-4 py-8 font-medium">
    For non-affiliates to market to you
    </td>
    <td class="border px-4 py-8 font-medium text-center">
    NO
    </td>
    <td class="border px-4 py-8 font-medium text-center">
    WE DON’T SHARE
    </td>
  </tr>
      </tbody>
    </table>
  </div>`
  },
  {
    className: 'text-center',
    text: 'Questions? Call 866.395.2754'
  },
  {
    className: '',
    text: `<div class="overflow-hidden">
    <table class="table-fixed pt-2 ${LEGAL_STYLES.textContent}">
      <tbody>
        <tr>
          <td class="border px-6 py-8 font-medium text-center">
            WHO WE ARE
          </td>
          <td class="border px-6 py-8 font-medium"> </td>
        </tr>
        <tr class="bg-light-blue-200">
          <td class="border px-6 py-8 font-medium text-center">
            Who is providing this notice?
          </td>
          <td class="border px-6 py-8 font-medium">
            Evolve Bank & Trust
          </td>
        </tr>
        <tr>
          <td class="border px-6 py-8 font-medium text-center">
            WHAT WE DO
          </td>
          <td class="border px-6 py-8 font-medium"></td>
        </tr>
        <tr>
          <td class="border px-6 py-8 font-medium text-center">
            How does Evolve Bank & Trust protect my personal information?
         </td>
          <td class="border px-6 py-8 font-medium">
            To protect your personal information from unauthorized access
            and use, we use security measures that comply with federal law.
            These measures include computer safeguards and secured files and
            buildings.
          </td>
        </tr>
        <tr>
          <td class="border px-6 py-8 font-medium text-center">
            How does Evolve Bank & Trust collect my personal information?
          </td>
          <td class="border px-6 py-8 font-medium">
            We collect your personal information, for example, when you
            <p>• Open an account or provide account information</p>
            <p>• Use your credit or debit card or apply for financing</p>
            <p>• Give us your contact information</p>
            We also collect your personal information from others,
            such as credit bureaus, affiliates, or other companies.
          </td>
        </tr>
        <tr>
          <td class="border px-6 py-8 font-medium text-center">
            Why can’t I limit all sharing?
          </td>
         <td class="border px-6 py-8 font-medium">
            Federal law gives you the right to limit only
            <p>• Sharing for affiliates’ everyday business purposes – information
            about your creditworthiness</p>
            <p>• Affiliates from using your information to market to you</p>
            <p>• Sharing for non-affiliates to market to you</p>
            State laws and individual companies may give you additional rights to
            limit sharing. See below for more on your rights under state law.
         </td>
        </tr>
      </tbody>
    </table>
  </div>`
  },
  {
    className: 'text-center',
    text: 'Definitions'
  },
  {
    className: '',
    text: `<div class="overflow-hidden">
    <table class="table-fixed pt-2 ${LEGAL_STYLES.textContent}">
      <tbody>
        <tr>
          <td class="border w-24 py-8 font-medium text-center">
          Affiliates
          </td>
          <td class="border px-6 py-8 font-medium">
          Companies related by common ownership or control. They can be financial and non-financial companies.
          <p class="italic">• Our affiliates include companies with an Evolve name, such as Evolve Bancorp, Inc.</p>
          </td>
        </tr>
        <tr class="bg-light-blue-200">
          <td class="border w-24 py-8 font-medium text-center">
          Non-Affiliates
          </td>
          <td class="border px-6 py-8 font-medium">
          Companies not related by common ownership or control. They can be financial and non-financial companies.
          <p class="italic">• Non-affiliates we share with can include insurance companies, service providers, co-branded partners,
          retailers, data processors, membership clubs, companies engaged in selling of consumer products and services,
          financial services companies, banks, and mortgage servicing companies.</p>
          </td>
        </tr>
        <tr>
          <td class="border w-24 py-8 font-medium text-center">
          Joint Marketing
          </td>
          <td class="border px-6 py-8 font-medium">
          A formal agreement between nonaffiliated financial companies that together market financial products or services to you.
          <p class="italic">• Our joint marketing partners include companies such as financial services companies and other financial institutions.</p>
          </td>
        </tr>`
  },
  {
    className: 'text-center',
    text: 'OTHER IMPORTANT INFORMATION:'
  },
  {
    className: '',
    text:
      '<span class="font-bold">VT:</span> Accounts with a Vermont mailing address are automatically treated as if they have limited the sharing as described on page 1.'
  },
  {
    className: '',
    text:
      '<span class="font-bold">NV:</span> If you prefer not to receive marketing calls from us, you may be placed on our Internal Do Not Call List by calling 866.395.2754 or by writing to us at: 6070 Poplar Ave, Suite 100, Memphis, TN 38119. For more information, contact us at the address in the preceding sentence.'
  },
  {
    className: 'none-space',
    text: 'You may also contact the Nevada Attorney General’s Office:'
  },
  {
    className: 'none-space',
    text: 'Bureau of Consumer Protection'
  },
  {
    className: 'none-space',
    text: 'Office of the Nevada Attorney General'
  },
  {
    className: 'none-space',
    text: '555 E. Washington St., Suite 3900,'
  },
  {
    className: 'none-space',
    text: 'Las Vegas, NV 89101'
  },
  {
    className: 'none-space',
    text: 'tel: 702.486.3132'
  },
  {
    className: 'none-space',
    text:
      'email: <a href=mailto:BCPINFO@ag.state.nv.us class="cursor-pointer text-highlight">BCPINFO@ag.state.nv.us</a>'
  },
  {
    className: '',
    text: `You have the right to opt-out of the sale of certain personal data to third parties. You can exercise this right by contacting us at the above address and providing us with your name and the account number. Please note that we do not currently sell your personal data as sales are defined in Nevada Revised Statutes Chapter 603A. You may also fill out our <a href=mailto:help@${SITE_DATA.domainMailto} class="cursor-pointer text-highlight">contact form</a> to exercise rights under this section.`
  },
  {
    className: '',
    text:
      '<span class="font-bold">CA:</span> Under California law, we will not share information we collect about you with companies outside of Evolve Bank & Trust, unless the law allows. For example, we may share information to service your accounts, or to provide rewards or benefits you are entitled to. We will limit sharing among our companies to the extent required by California law.'
  },
  {
    className: '',
    text: `UPDATED: September 2020`
  }
];
