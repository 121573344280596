import { SITE_DATA } from 'public/constants/common';

export const DOGEKING_EVOLVE_BANK_ELECTRONIC = [
  {
    className: '',
    text: `Effective Date: August 26, 2021`
  },
  {
    className: '',
    text: `In order for you to establish an account with or otherwise obtain products and services from Liquidity Financial, inc. doing business as ‘${SITE_DATA.appName}’, you must agree that we may provide notices and other communications (the “Communications”), including those required by law to be provided “in writing,” to you electronically as described in this agreement (the “Consent Agreement”). Certain laws and regulations require us to provide communications to you “in writing,” which means you may be entitled to receive the information on paper. The E-SIGN Act allows us to provide you communications electronically and to conduct transactions with you electronically, with your consent. For purposes of this Consent Agreement, the terms “we,” “us,” “our,” and ${SITE_DATA.appName}” refer to Liquidity Financial, inc. and its service providers and financial institution partners, including but not limited to; Solid Financial Technologies, Inc.; Evolve Bank & Trust; and such entities’ respective affiliates, subsidiaries, agents, assigns, and service providers, and the terms “you” or “your” refer to you as an individual, any entity you represent, and any joint accountholder or authorized user on your accounts with us.`
  },
  {
    className: '',
    text:
      'By agreeing to the terms of this Consent Agreement, you are agreeing to receive Communications electronically (whether by e-mail or SMS text message or through our website, our mobile application, or otherwise as determined by us) in connection with all accounts you maintain or apply to open with us, and any products or services you receive from us, now or in the future.'
  },
  {
    className: 'bold-text',
    text: 'Scope of Communications to be Provided in Electronic Form.'
  },
  {
    className: '',
    text: `This Consent Agreement applies to all Communications and Products. By applying for or using a Product of ${SITE_DATA.appName} you agree that any Communications will be provided in electronic format, to the extent allowed by law, and that paper Communications will not be sent.  Your consent to receive electronic Communications and transactions includes, but is not limited to:`
  },
  {
    className: 'dash-text',
    text: '- All legal and regulatory disclosures and communications;'
  },
  {
    className: 'dash-text',
    text:
      '- The Account Terms, including any amendments thereto, and any and all agreements by and between you and us;'
  },
  {
    className: 'dash-text',
    text: '- Privacy policies and notices;'
  },
  {
    className: 'dash-text',
    text: '- Responses to claims filed;'
  },
  {
    className: 'dash-text',
    text: '- Statements; and'
  },
  {
    className: 'dash-text',
    text: `- All other communications between us and you concerning the ${SITE_DATA.appName} products and services, and any related transactions.`
  },
  {
    className: '',
    text:
      'Sometimes the law, or our agreement with you, requires you to give us a written notice. You must still provide these notices to us on paper, unless we specifically tell you in another Communication how you may deliver that notice to us electronically.'
  },
  {
    className: '',
    text:
      'We reserve the right, at our sole discretion, to discontinue the provision of electronic Communications, or to terminate or change the terms and conditions upon which electronic Communications are provided.  We will provide you with notice of any such termination or change as required by law.'
  },
  {
    className: 'bold-text',
    text: 'Method of Providing Communications in Electronic Form.'
  },
  {
    className: '',
    text:
      'All Communications that we provide in electronic form will be provided either (1) by e-mail or (2) by access to a website designated in  an email notice from us, (3) through the any mobile application we may make available, (4) to the extent permitted by law, by access to  a website generally designated in advance for such purpose, or (5) in the manner specified in any other agreement we or our affiliates  have with you.'
  },
  {
    className: '',
    text:
      'If you seek to obtain a new product, service or account with us, we may remind you that you have already consented to receiving electronic Communications and using electronic signatures in your relationship with us.'
  },
  {
    className: '',
    text: `Continuing to use ${SITE_DATA.appName} products and services after receiving updates to our system requirements signifies your acceptance of the change and reaffirmation of your consent.`
  },
  {
    className: 'bold-text',
    text: 'Keeping your Records Current.'
  },
  {
    className: '',
    text: `It is your responsibility to provide us with a true, accurate and complete e-mail address, street address, and other information related to this Consent and ${SITE_DATA.appName} products and services, and to maintain and update promptly any changes in this information. You can update information (such as your e-mail address) by contacting us at <a href=mailto:help@${SITE_DATA.domainMailto} class="cursor-pointer text-highlight">help@${SITE_DATA.domainMailto}</a> or from within the ${SITE_DATA.appName} app (if offered). We are not responsible for any delay or failure in the receipt of the Communications if we send the Communications to the last e-mail address you provided to us.`
  },
  {
    className: 'bold-text',
    text: 'System Requirements for Accessing Communications.'
  },
  {
    className: '',
    text:
      'In order to access, view, and retain electronic Communications that we make available, you must have:'
  },
  {
    className: 'dash-text',
    text: '- Computers capable of running one of these compatible browsers:'
  },
  {
    className: 'dash-text',
    text: '- Internet Explorer version 9.0 or higher.'
  },
  {
    className: 'dash-text',
    text: '- Firefox version 35 or higher.'
  },
  {
    className: 'dash-text',
    text: '- Safari version 6.1 or higher.'
  },
  {
    className: 'dash-text',
    text: '- Chrome version 38 or higher.'
  },
  {
    className: '',
    text: 'OR'
  },
  {
    className: 'dash-text',
    text: '- An Apple iPhone or iPad running iOS version 9.0 or higher.'
  },
  {
    className: 'dash-text',
    text: '- Access to an active e-mail account with an email service provider.'
  },
  {
    className: '',
    text:
      'We may update these requirements as necessary to preserve the ability to receive electronic Communications. If there is a substantial change in these requirements, you will be notified as required by law.'
  },
  {
    className: 'bold-text',
    text: 'Requesting Paper Copies.'
  },
  {
    className: '',
    text: `We will not send paper copies of any Communication; however, we reserve the right, but assume no obligation, to provide a paper (instead of electronic) copy of any Communication that you have authorized us to provide electronically. You can obtain a paper copy of an electronic Communication by printing it or by requesting that we mail a paper copy. To request a paper copy, call us at <a href=mailto:help@${SITE_DATA.domainMailto} class="cursor-pointer text-highlight">help@${SITE_DATA.domainMailto}</a> during normal business hours, except for national holidays. There may be a fee associated with the request for the delivery of paper copies of any Communication provided electronically pursuant to this Consent.`
  },
  {
    className: 'bold-text',
    text: 'Communications in Writing.'
  },
  {
    className: '',
    text:
      'All Communications in either electronic or paper format from us to you will be considered “in writing.” You should print or download a copy of this Consent and any other Communications for your records.'
  },
  {
    className: 'bold-text',
    text: 'Withdrawing Your Consent'
  },
  {
    className: '',
    text: `You can withdraw your consent to receive Communications electronically at any time. Your withdrawal of consent will become effective after we have had a reasonable opportunity to act upon it. To withdraw your consent to receive Communications electronically, you must contact us by e-mailing at <a href=mailto:help@${SITE_DATA.domainMailto} class="cursor-pointer text-highlight">help@${SITE_DATA.domainMailto}</a>.`
  },
  {
    className: '',
    text: `If you withdraw consent, your access to all ${SITE_DATA.appName} products and services will be terminated and any account you have with us will be closed and funds will be returned to you in accordance with the account agreement. If you withdraw consent, the legal validity and enforceability of prior Communications delivered in electronic form will not be affected.`
  },
  {
    className: 'bold-text',
    text: 'Consent'
  },
  {
    className: '',
    text: `By applying for or using a ${SITE_DATA.appName} product or service, or by checking any call to action (including “I Agree” or similar language), you give us affirmative consent to receive electronic Communications as described herein.`
  },
  {
    className: '',
    text:
      'By providing your consent, you are also confirming that you have the hardware and software described above, that you are able to receive and review electronic Communications, and that you have an active email account. You are also confirming that you are authorized to, and do, consent on behalf of all the other account owners, authorized signers, authorized representatives, delegates, product owners and/or service users identified with your Products.'
  },
  {
    className: 'bold-text',
    text: `By clicking on “I Agree”, you (and any entity on whose behalf you are applying to open an account or otherwise using our services) are representing and warranting to ${SITE_DATA.appName} the following: (1) you have read, understand and agree to the terms in this Consent Agreement; (2) agree to receive Covered Items electronically and to the other terms of this Consent Agreement; (3) confirm that you satisfy the System Requirements set forth above; (4) confirm that you are able to access and print or store the Covered Items and the information presented at this website; (5) you have an active e-mail address and PDF reader and have the ability to access PDF files; (6) you understand that ${SITE_DATA.appName} will rely on the truthfulness and completeness of your representations and warranties above; (7) you consent to receive Covered Items, as are now or in the future available, in connection with your accounts at ${SITE_DATA.appName} or in connection with your relationship with ${SITE_DATA.appName}, including any ${SITE_DATA.appName} account(s) that you may subsequently open or for which you apply; (8) you agree to update your contact information as needed in order to ensure timely receipt of Covered Items and that our records will control in the event of any dispute over which contact information you have provided to us; (9) you are agreeing on behalf of all other account holders, co-owners or other authorized persons, if any, and you have the power and authority to consent and agree on their behalf and on behalf of any entity for which you are acting; (10) you understand and agree that we may terminate certain agreements and services provided to you if you withdraw your Consent; and (11) your agreement and Consent is effective and valid until you withdraw your Consent as provided above.`
  },
  {
    className: 'bold-text',
    text:
      'If you do not agree to the terms of this Consent, please do not click “I Agree”.'
  }
];
