import { ICONS_FEATURES } from './common';

export const HOME_CONTENTS = {
  zeroHash: '',
  listCrypto: '<li class="get-coin mb-4">Dogecoin-back</li>',
  listSecure: '',
  protection: 'Solid',
  iconWidth: 182,
  iconHeight: 57,
  featureInfo: 'Fraud Protection <br/> Zero-Liability guarantee',
  featureIconPath: `${ICONS_FEATURES.solid}`,
  charitiesTitle: ''
}

export const REFERRAL_CONTENTS = {
  inviteContent: 'Bonus'
}
