export const IMAGES_CARD = {
  cardVisa: '/common/cards/card-visa-vertical.png',
  cardVisaBlack: '/common/cards/card-visa-black-vertical.png'
};

export const ICONS_HEADER = {
  fdicMember: '/icons/fdic-member-logo.svg',
  frameMemberBlack: '/icons/fdic-member.svg'
};

export const ICONS_PARTNERS = {
  evolveBank: '/icons/partners/evolve-bank.svg',
  solid: '/icons/partners/solid.svg',
  zero: '/icons/partners/zero.svg',
  visa: '/icons/partners/visa.svg'
};

export const IMAGES_LANDING = {
  frameComplete: '/frame-complete.png',
  frameConnect: '/frame-connect.png',
  frameContinue: '/frame-continue.png',
  framePhone: '/frame-phone.png',
  frameDoge: '/frame-doge.png',
  rewards: '/rewards.png',
  bgLanding: '/bg-landing.png',
  fees: '/fees.png',
  stayControl: '/stay-control.png',
  earlyPayment: '/early-payment.gif',
  referFriends: '/refer-friends.png',
  frameMember: '/frame-member.png',
  frameEvolveBank: 'frame-evolve-bank.png'
};

export const TEAMS = {
  ryan: 'common/teams/ryan.png',
  andrew: 'common/teams/andrew.png',
  chloe: 'common/teams/chloe.png',
  emezie: 'common/teams/emezie.png',
  glauber: 'common/teams/glauber.png',
  jason: 'common/teams/jason.png',
  rick: 'common/teams/rick.png',
  roman: 'common/teams/roman.png',
  yadiyah: 'common/teams/yadiyah.png'
};

export const ICON_PLAY = '/icons/play.svg';
