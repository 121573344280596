import { ROUTES } from './routes';
export const LINKS_TO = {
  getEvolved: 'https://www.getevolved.com/'
};

export const SITE_DATA = {
  dogeCardSkeleton: false,
  dogeCard: false,
  prideCard: false,
  terraCard: false,
  avalancheCard: false,
  choiceCard: false,
  liquidityCard: false,
  shibCard: false,
  causeCard: false,
  pawsCard: true,
  firstAppName: 'Paws',
  appName: 'PawsCard',
  secondaryAppName: '',
  website: 'pawsCard',
  siteName: 'Marketing Website',
  coinName: 'Shib',
  bannerTitle: 'Get your <br/> Paws Card',
  subBannerTitle: '',
  inputPlaceholderText: 'Enter email',
  domain: process.env.NEXT_PUBLIC_DOMAIN,
  domainMailto: 'pawscard.co',
  phoneNumber: '(424) 272-7344'
}

export const NAV_HEADER = []

export const SIZE_IMAGE = {
  logo: {
    width: 80,
    height: 80
  },
  secondaryLogo: {
    width: 50,
    height: 50
  },
  tertiary: {
    width: 100,
    height: 100
  },
  fdicMember: {
    width: 70,
    height: 47
  },
  cardReferral: {
    width: 434,
    height: 321
  },
  playIcon: {
    width: 28,
    height: 28
  }
}

export const CONTENT_WRAPPER = {
  classNameWrapperContent: 'w-full md:pt-30 xl:pt-0',
  classNameWrapperFeatures: 'relative z-20 2xl:my-20 my-10 mx-7 sm:mx-10 md:my-14 lg:mx-20 2xl:px-28 2xl:max-w-screen-3xl 2xl:mx-auto',
}

export const CARD_INFO = [
  {
    className: '',
    bgClassName: 'frame-complete px-7 sm:px-10 lg:px-24 xl:px-28 2xl:px-40 2xl:max-w-screen-3xl 3xl:mx-auto',
    icons: ['star-icon star-complete-left', 'star-icon star-complete-right', 'twisted-arrow twisted-arrow-complete'],
    claimCardInfo: {
      className: 'flex justify-center md:justify-end',
      titleClassName: '2xl:my-0',
      labelButton: 'Join Waitlist',
      titleHeader: 'Choose your rewards',
      titleInfo: {
        title: 'Get 1% back on every purchase, and choose how you would like your reward:'
      }
    },
    imageInfo: {
      className: 'bg-frame-complete',
      wrapperClassName: 'md:bg-left lg:bg-center 2xl:mt-10 w-full',
      imageClassName: 'h-128 md:h-144 2xl:h-198 pt-6 md:pr-10',
      alt: 'Frame Complete',
      width: 644,
      height: 704
    }
  },
  {
    className: 'py-10 2xl:py-36 px-7 sm:px-10 lg:px-24 xl:px-28 2xl:px-44 2xl:max-w-screen-3xl 3xl:mx-auto',
    bgClassName: 'frame-member bg-card-wrapper',
    icons: ['star-icon star-member', 'curved-arrow curved-arrow-member'],
    claimCardInfo: {
      className: '',
      titleClassName: '',
      labelButton: 'Join Waitlist',
      titleHeader: 'Safe and Secure',
      titleInfo: {
        title: ''
      }
    },
    imageInfo: {
      className: 'bg-frame-member',
      wrapperClassName: 'md:bg-right w-full',
      imageClassName: 'h-24 sm:h-28 md:h-32 2xl:h-40 md:px-20',
      alt: 'Frame Member',
      width: 254,
      height: 156,
      linkTo: 'LINKS_TO.getEvolved'
    }
  },
  {
    className: '',
    bgClassName: 'fees px-7 sm:px-10 lg:px-24 xl:px-28 2xl:px-40 2xl:max-w-screen-3xl 3xl:mx-auto',
    icons: ['star-icon star-fees', 'twisted-arrow twisted-arrow-fees'],
    claimCardInfo: {
      className: '2xl:w-1/2 2xl:mt-11 2xl:min-w-content-text flex justify-center md:justify-end',
      titleClassName: '2xl:max-w-2xl',
      labelButton: 'Join Waitlist',
      titleHeader: 'Bye, fees',
      titleInfo: {
        title: 'No overdraft fees, no minimum balance fees and no ATM-fees from 32,000 MoneyPass ATMs.'
      }
    },
    imageInfo: {
      className: 'bg-fees',
      imageClassName: 'h-48 2xl:h-56 md:pr-10 2xl:mt-10 2xl:w-1/2',
      wrapperClassName: 'md:bg-left w-3/4 sm:w-2/3 md:w-full',
      alt: 'Frame Fees',
      width: 572,
      height: 244
    }
  },
  {
    className: '',
    bgClassName: 'frame-phone px-7 sm:px-10 lg:px-24 xl:px-28 2xl:px-44 2xl:max-w-screen-3xl 3xl:mx-auto',
    claimCardInfo: {
      className: 'pt-6',
      titleClassName: '2xl:max-w-2xl',
      labelButton: 'Join Waitlist',
      titleHeader: 'Get paid early',
      titleInfo: {
        title: 'Set up direct deposit and get your paycheck up to 2 days earlier than your teammates!'
      }
    },
    imageInfo: {
      className: 'bg-frame-phone',
      wrapperClassName: 'md:bg-right w-full',
      imageClassName: 'h-96 sm:h-104 md:h-112 xl:h-128 2xl:h-198 pt-10',
      alt: 'Frame Phone',
      width: 837,
      height: 837
    }
  },
  {
    className: 'py-10 2xl:py-36 px-7 sm:px-10 lg:px-24 xl:px-28 2xl:px-40 2xl:max-w-screen-3xl 3xl:mx-auto',
    bgClassName: 'stay-control bg-card-wrapper',
    claimCardInfo: {
      className: '2xl:min-w-content-text flex justify-center md:justify-end',
      titleClassName: '',
      labelButton: 'Join Waitlist',
      titleHeader: 'Stay in control',
      titleInfo: {
        title: 'You’re always in-the-know with notifications and transaction alerts'
      }
    },
    imageInfo: {
      className: 'bg-stay-control',
      wrapperClassName: 'md:bg-left w-full mb-10 md:mb-0',
      imageClassName: 'h-40 sm:h-52 2xl:h-56 md:pr-8',
      alt: 'Frame Phone',
      width: 668,
      height: 215
    }
  }
]

export const CONTACTS_INFO = {
  phoneNumber: '(855) 217-9378',
  address: '1925 Century Park E, Los Angeles, CA 90067'
};

export const BUTTON_DOWNLOAD = [];

export const INFO_TOLL = [];

export const POPUP_ALERT = {
  messageTitle: "Waitlist joined!",
  messageSub: "Your email address has been added to the waitlist. We will email updates."
};

export const SOCIAL_LINKS_SHARE = [];

export const FOOTER_INFO = {
  copyRights: '2023'
};

export const ABOUT_US_INFO = {
  bannerTitle: '',
  videoLink: '',
  classNameBanner: '',
  subTitle: '',
  info: ''
};

export const COMMUNITY_INFO = {
  titleHead: '',
  subTitle: '',
  titleBanner: {
    title: '',
    subTitle: ''
  }
};

export const CHARITIES_INFO = [];

export const SEEN_IN_DATA = [];

export const ICONS_FEATURES = {
  fdicMember: '/icons/fdic-member.svg',
  union: '/icons/union.svg',
  solid: '/icons/solid.svg'
};
