import axios from 'axios';
import { API_ROUTES } from '../constants/api-routes';
import { CONNECTION } from '../constants/common';
import { santizerPhone } from '../utils/helper';

axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_ENDPOINT;
axios.defaults.headers.common['x-api-key'] = process.env.NEXT_PUBLIC_API_KEY;

/**
 * Sign in or sign up by email
 * @param email
 * @returns
 */
export const signInByEmail = async ({
  email = '',
  name = '',
  // cardType = '',
  inviterReferralCode = ''
}) => {
  return axios.post(API_ROUTES.SIGN_IN, {
    connection: 'email',
    email: email,
    phone: '',
    // name,
    // cardType,
    inviterReferralCode
  });
};

/**
 * Sign in or sign up by phone
 * @param phone
 * @returns
 */
export const signInByPhone = async ({
  phone = '',
  // name = '',
  // cardType = '',
  inviterReferralCode = ''
}) => {
  return axios.post(API_ROUTES.SIGN_IN, {
    connection: 'sms',
    email: '',
    phone: santizerPhone(phone),
    // name,
    // cardType,
    inviterReferralCode
  });
};

/**
 * Verify OTP by email
 * @param param0
 * @returns
 */
export const verifyOTPByEmail = async ({
  email = '',
  otp = '',
  referralCode = ''
  // name = '',
  // cardType = ''
}) => {
  return axios.post(API_ROUTES.VERIFY_OTP, {
    otp: otp,
    connection: 'email',
    username: email,
    referralCode: referralCode
    // name,
    // cardType
  });
};

/**
 * Verify OTP by phone
 * @param param0
 * @returns
 */
export const verifyOTPByPhone = async ({
  phone = '',
  otp = '',
  referralCode = ''
  // name = '',
  // cardType = ''
}) => {
  return axios.post(API_ROUTES.VERIFY_OTP, {
    otp: otp,
    connection: 'sms',
    username: santizerPhone(phone),
    referralCode: referralCode
    // name,
    // cardType
  });
};

export const signIn = async ({
  account = '',
  connection = '',
  // name = '',
  // cardType = '',
  inviterReferralCode = ''
}) => {
  if (connection === CONNECTION.EMAIL) {
    return signInByEmail({
      email: account,
      // name,
      // cardType,
      inviterReferralCode: inviterReferralCode || ''
    });
  } else {
    return signInByPhone({
      phone: `+1${account}`,
      // name,
      // cardType,
      inviterReferralCode: inviterReferralCode || ''
    });
  }
};

export const verifyOTP = async ({
  connection = '',
  account = '',
  otp = '',
  referralCode = ''
  // name = '',
  // cardType = ''
}) => {
  if (connection === CONNECTION.EMAIL) {
    return verifyOTPByEmail({
      email: account,
      otp: otp,
      referralCode: referralCode || ''
      // name,
      // cardType
    });
  } else {
    return verifyOTPByPhone({
      phone: `+1${account}`,
      otp: otp,
      referralCode: referralCode || ''
      // name,
      // cardType
    });
  }
};

/**
 * Refresh token
 * @param param0
 * @returns
 */
export const getRefreshToken = async ({ refreshToken = '' }) => {
  return axios.post(API_ROUTES.REFRESH_TOKEN, {
    refreshToken
  });
};

export const getAuthAccount = async ({ userId = '' }) => {
  return axios.post(API_ROUTES.AUTH_ACCOUNT, {
    userId
  });
};

export const linkAuthAccount = async ({
  email = '',
  phone = '',
  connection = ''
}) => {
  return axios.post(API_ROUTES.LINK_ACCOUNT, {
    email,
    phone,
    connection
  });
};

export const getReferralRewards = async () => {
  return axios.get(API_ROUTES.REFERRAL_REWARDS);
};

/**
 * Post contact us
 * @param param0
 * @returns
 */
export const sendContactUs = async ({
  email = '',
  name = '',
  website = '',
  message = ''
}) => {
  return axios.post(API_ROUTES.CONTACT_US, {
    email: email,
    name: name,
    website: website,
    message: message
  });
};

/**
 * Send SMS to phone
 * @param param0
 */
export const sendSMS = async ({ phone = '', type = '', referralCode = '' }) => {
  return axios.post(API_ROUTES.SEND_SMS, {
    phone,
    type,
    referralCode
  });
};

/**
 * Sign up by email
 * @param email
 * @returns
 */
export const signUpByEmail = async ({ email = '' }) => {
  return axios.post(API_ROUTES.SIGN_IN, {
    email: email
  });
};

export const joinWaitlistByEmail = async ({ email = '' }) => {
  return axios.post(
    API_ROUTES.JOIN_WAITLIST,
    {
      email
    },
    {
      headers: {
        solid_product_id: `${process.env.NEXT_PUBLIC_SOLID_PRODUCT_ID}`
      }
    }
  );
};

/**
 * Sign up by email or phone
 * @returns
 */
export const signUpByEmailOrPhone = async ({ email = '', phone = '' }) => {
  return axios.post(API_ROUTES.SIGN_IN, {
    email: email,
    phone: phone
  });
};

/**
 * get FDIC insurance
 * @returns string
 */
export const getFdicInsurance = async () => {
  return axios.get(API_ROUTES.FDIC_INSURANCE);
};
