declare global {
  interface Window {
    analytics: any;
  }
}

export const segmentSendEventData = data => {
  /**
    UserID
    Signup timestamp
    Email
    Email verified
    Phone Number
    Phone Verified
    Referral Code
    Referral Link
    Referred by (users referral code who referred them)
    Referral Bonus
   */
  if (window.analytics) {
    window.analytics.identify(data.userId, data);
  }
};

export const segmentTrackEventData = (name, data) => {
  if (window.analytics) {
    window.analytics.track(name, data);
  }
};
