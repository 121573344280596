import { isBrowser } from './helper';

export const STORAGE_KEYS = {
  USER_NAME: 'userName',
  PHONE: 'phone',
  ACCOUNT: 'account',
  EMAIL: 'email',
  CONNECTION: 'connection',
  SELECTED_CARD: 'selectedCard',
  AUTH_TOKEN: 'authToken',
  REFERRAL_CODE: 'referralCode',
  INVITER_REFERRAL_CODE: 'inviterReferralCode',
  USER_METADATA: 'userMetadata',
  INVITEE_REWARD_AMOUNT: 'inviteeRewardAmount',
  REWARD_AMOUNT: 'rewardAmount',
  FDIC_INSURANCE: 'fdicInsurance'
};

export const setStorage = (key = '', data) => {
  if (isBrowser) {
    localStorage.setItem(key, data);
  }
};

export const getStorage = (key = '') => {
  return isBrowser ? localStorage.getItem(key) : '';
};

export const removeStorage = (key = '') => {
  return isBrowser ? localStorage.removeItem(key) : '';
};

export const deleteStorage = () => {
  isBrowser && localStorage.clear();
};

export const resetAccountData = () => {
  setStorage(STORAGE_KEYS.USER_NAME, '');
  setStorage(STORAGE_KEYS.PHONE, '');
  setStorage(STORAGE_KEYS.EMAIL, '');
  setStorage(STORAGE_KEYS.CONNECTION, '');
  setStorage(STORAGE_KEYS.ACCOUNT, '');
  setStorage(STORAGE_KEYS.SELECTED_CARD, '');
  setStorage(STORAGE_KEYS.INVITEE_REWARD_AMOUNT, '');
};
