export const ROUTES = {
  HOME: '/',
  FAQ: '/#faq',
  NOT_FOUND: '/404',
  VERIFICATION_CODE: '/verification-code',
  REFERRAL_PAGE: '/next',
  ABOUT_US: '/about-us',
  COMMUNITY: '/community',
  WHITE_PAPER: '/whitepaper',
  APP: '/app',
  FAQ_PAGE: '/faq',
  SIGN_IN: '/sign-in',
  LEGAL_PAGE: '/legal',
  LEGAL_PRIVACY_PAGE: '#privacy-policy',
  LEGAL_PRIVACY_PAGE_LINK: '/legal/#privacy-policy',
  LEGAL_ELECTRONIC_PAGE: '#e-sign-consent',
  LEGAL_ELECTRONIC_PAGE_LINK: '/legal/#e-sign-consent',
  LEGAL_CONSUMER_ACCOUNT_PAGE: '#consumer-account',
  LEGAL_CONSUMER_ACCOUNT_PAGE_LINK: '/legal/#consumer-account',
  LEGAL_REFERRALS_PAGE: '#referral-terms',
  LEGAL_REFERRALS_PAGE_LINK: '/legal/#referral-terms',
  LEGAL_REWARDS_PAGE: '#rewards',
  LEGAL_REWARDS_PAGE_LINK: '/legal/#rewards',
  LEGAL_VISA_CREDIT_PAGE: '#visa-credit',
  LEGAL_VISA_CREDIT_PAGE_LINK: '/legal/#visa-credit',
  LEGAL_PLATFORM_TERMS_PAGE: '#platform-terms',
  LEGAL_PLATFORM_TERMS_PAGE_LINK: '/legal/#platform-terms',
  LEGAL_SOLID_PRIVACY_PAGE: '#solid-privacy',
  LEGAL_SOLID_PRIVACY_PAGE_LINK: '/legal/#solid-privacy',
  LEGAL_BANK_TRUST_CONSUMER_PAGE: '#bank-trust-consumer',
  LEGAL_BANK_TRUST_CONSUMER_PAGE_LINK: '/legal/#bank-trust-consumer',
  LEGAL_BANK_TRUST_POLICY_PAGE: '#bank-trust-policy',
  LEGAL_BANK_TRUST_POLICY_PAGE_LINK: '/legal/#bank-trust-policy',
  LEGAL_EVOLVE_E_CONSENT_PAGE: '#evolve-e-consent',
  LEGAL_EVOLVE_E_CONSENT_PAGE_LINK: '/legal/#evolve-e-consent',
  LEGAL_EVOLVE_DEPOSIT_PAGE: '#evolve-deposit',
  LEGAL_EVOLVE_DEPOSIT_PAGE_LINK: '/legal/#evolve-deposit',
  LEGAL_DEAL_REWARDS_PAGE: '#deal-rewards',
  LEGAL_DEAL_REWARDS_PAGE_LINK: '/legal/#deal-rewards',
  ABTASTY_EDITOR: '?abtasty_editor'
};
