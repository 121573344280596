export const CONTACT_FORM_STYLES = {
  contentWrapper: '',
  inputText: 'placeholder-black placeholder-opacity-25',
  errorText: '',
  buttonText: '',
  successText: 'text-dark-light'
}

export const HOME_STYLES = {
  contentWrapper: '',
  itemTitle: 'font-quaternary-bold max-w-md md:max-w-2xl text-3xl md:text-4xl text-dark-light xl:leading-lg',
  listItemText: 'xl:text-3xl text-dark-light xl:leading-lg font-quaternary-regular font-normal',
  itemSubtitle: 'xl:text-3xl text-dark-light xl:leading-lg max-w-md md:max-w-xl',
  sizeButton: 'default'
}

export const GET_CARD_INFO_STYLES = {
  contentLayout: 'mt-20 2xl:mt-32',
  imageInfoLayout: 'xl:py-12',
  imageInfo: 'xl:text-2xl',
  imageLinkLayout: 'w-1/2 lg:justify-start md:mb-20 mb-10 2xl:mb-0 2xl:pr-24',
  cardInfoLayout: 'px-0 2xl:max-w-4xl'
}

export const REFERRAL_STYLES = {
  timeCountDown: 'text-info-black'
}

export const TOP_VIDEO_STYLES = {
  emailInputWrapper: 'w-full sm:max-w-md sm:w-2/3 lg:w-1/2',
  emailInput: 'text-base leading-xs sm:text-lg text-dark-bland sm:leading-base font-quaternary-regular placeholder-dark-bland placeholder-opacity-75 email-input',
  textTermWrapper: 'text-term-banner sm:max-w-md sm:leading-xs pr-10 text-sm sm:pr-0 font-quaternary-regular mt-4',
  buttonWrapper: 'sm:w-48 sm:ml-9',
  formWrapper: 'mt-4 sm:mt-6 xl:mt-8',
  textTerm: 'md:max-w-md mt-1 text-highlight text-sm font-bold',
  textTitle: 'font-quaternary-black text-4xl font-black text-black text-center mx-auto sm:mx-0 sm:text-left sm:text-4xl sm:leading-lg xl:leading-2lg xl:text-5xl 2xl:text-6xl 2xl:leading-4xl max-w-xs max-w-xs sm:max-w-sm md:max-w-xl xl:max-w-2xl',
  textSubTitle: '',
  textTermSize: 'small',
  textTermColor: 'text-dark-light opacity-70 font-normal',
  titlePosition: 'md:px-10 2xl:px-25 2xl:top-1/2 sm:top-13/20 lg:top-6/10 top-7/10 sm:max-w-4xl',
  classBgVideo: 'h-200 pt-28 sm:h-auto sm:pt-0',
  bannerBgLayout: 'z-10 xl:min-h-banner-medium',
  bannerWrapper: 'mb-10 xl:mb-0'
}

export const HEADER_STYLES = {
  contentLayout: 'px-10 2xl:px-25 2xl:pt-14 sm:bg-transparent z-20',
  logoLayout: 'rounded-md',
  logoText: 'font-quaternary-regular font-normal md:text-xl xl:text-2xl text-dark-light',
  logoWrapper: 'mr-10 mt-3 md:mt-9 ',
  navText: 'sm:text-title',
  navActiveText: 'text-highlight',
  colorDropDownMenu: 'bg-eerie-black'
}

export const BUTTONS_STYLES = {
  bgColor: 'bg-primary',
  textColor: 'text-color-button font-quaternary-regular rounded-base sm:leading-base',
  bgButtonResendCode: 'bg-btn-resend',
  textColorBtnResendCode: 'text-btn-resend-code'
}

export const VERIFICATION_STYLES = {
  inputTextPinCode: 'placeholder-black text-info-black placeholder-opacity-25'
}

export const FOOTER_STYLES = {
  bgWrapper: 'w-full bg-footer-dark 2xl:px-44 shadow-2xl pt-5 md:pt-12 xl:pt-16 2xl:pt-20 mx-auto px-5 2xl:px-0',
  contentLayout: '2xl:max-w-screen-3xl 2xl:mx-auto sm:px-10 xl:px-24 2xl:px-0' ,
  logoLayout: 'md:items-start sm:items-center md:mx-auto md:w-1/2 lg:w-1/3 py-8 lg:py-4 2xl:py-2',
  logoText: 'text-white font-quaternary-bold text-lg 2xl:text-2xl 2xl:leading-2sm whitespace-nowrap ml-2 2xl:ml-3',
  navText: '',
  contentText: 'font-quaternary-regular text-white font-normal text-opacity-75 text-base 2xl:text-lg 2xl:leading-ls-md 2xl:pl-6 md:pl-8',
  contentTextLink: '',
  logoContent: 'mb-12',
  contentTextLayout: 'md:mt-5 lg:mt-2 md:mx-auto',
  copyRights: 'font-quaternary-regular text-white font-normal justify-center text-xss sm:leading-tiny w-full',
  copyRightsLayout: 'py-2.5 md:py-5 lg:mt-16 2xl:mt-24'
};

export const FEATURES_STYLES = {
  contentLayout: 'pb-10 md:pb-0',
  contentWrapper: 'features-info bg-features p-10 2xl:py-12 2xl:pb-14',
  iconLayout: 'max-h-144 min-h-36',
  title: 'text-dark-light',
  contentText: 'font-quaternary-regular text-dark-light pt-4 font-normal sm:leading-md'
}

export const FAQ_STYLES = {
  bgWrapper: 'md:container md:mx-auto px-4 sm:px-6 pt-24 mb-20 sm:pt-28 xl:pt-40',
  contentLayout: 'py-40',
  bannerLayout: 'relative bg-banner-faq min-h-banner-faq',
  colorAnimationSpin: 'text-dark-light'
}

export const PARTNERS_STYLES = {
  contentLayout: '',
  iconLayout: '',
  contentTitle: '',
  title: '',
  subTitle: '',
  itemTitle: '',
  itemInfo: ''
}

export const OUR_TEAMS_STYLES = {
  contentLayout: '',
  title: '',
  itemWrapper: '',
  itemTitle: '',
  itemInfo: '',
  itemLink: ''
}

export const BANNER_STYLES = {
  contentLayout: '',
  bannerLayout: '',
  titleLayout: '',
  subTitleLayout: '',
  title: '',
  subTitle: '',
  bgImage: '',
  bgBanner: ''
}

export const CONTACT_STYLES = {
  contentLayout: '',
  title: '',
  subTitle: '',
  infoTitle: '',
  infoSubTitle: '',
  info: ''
}

export const ABOUT_US_STYLES = {
  contentLayout: '',
  bannerLayout: ''
}

export const LEGAL_STYLES = {
  contentLayout: 'bg-cover bg-top',
  textContent: 'text-black',
  textHighlight: 'text-highlight',
  textColor: 'text-black',
  textSize: 'small',
  listToggle: 'list-toggle',
  listToggleCollapsed: 'list-toggle--collapsed',
  listToggleExpanded: 'list-toggle--expanded'
}

export const BANNER_VIDEO_STYLES = {
  bannerContent: '',
  titleContent: '',
  subTitleContent: '',
  infoContent: ''
}

export const CHARITIES_STYLES = {
  bgLayout: '',
  contentLayout: '',
  iconLayout: ''
}
