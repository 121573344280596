import { ROUTES } from 'src/constants/routes';

const PHONE_PATTERN = /^(\d{3})(\d{3})(\d{4})$/;
const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isBrowser = typeof window !== 'undefined';
export const isEmail = (value = '') => value.match(EMAIL_PATTERN);
export const isPhone = (value = '') => !Number.isNaN(value);
const playStoreAppId = process.env.NEXT_PUBLIC_PLAY_STORE_APP_ID;
const appleStoreAppId = process.env.NEXT_PUBLIC_APPLE_STORE_APP_ID;

/**
 *
 * @param value +12345678900
 * @returns "+1(234)-567-8900"
 */
export const formatPhone = (value = '') => {
  var cleaned = ('' + value).replace(/\D/g, '');
  var match = cleaned.match(PHONE_PATTERN);

  if (match) {
    // var intlCode = match[1] ? '+1' : '';
    // var intlCode = '';
    return ['(', match[1], ')', '-', match[2], '-', match[3]].join('');
  }
  return value;
};
/**
 *
 * @param value "+1(234)-567-8900"
 * @returns +12345678900
 */
export const santizerPhone = (value = '') => `+${value.replace(/\D+/g, '')}`;
export const validatePhone = (value = '') => {
  const formattedValue = value.replace(/\D+/g, '');
  return formattedValue.length === 10 && formattedValue.match(PHONE_PATTERN);
};
export const validation = ({
  value = '',
  key = 'Email' // Email/Phone
}) => {
  if (!value) {
    return `${
      key !== 'Email' && key !== 'Phone'
        ? `Enter your ${key.toLowerCase()}`
        : 'Enter your email or phone'
    }`;
  }

  switch (key.toLowerCase()) {
    case 'phone':
      return !validatePhone(value) ? `${key} is invalid` : '';
    case 'email':
      return !isEmail(value) ? `Email is invalid` : '';
    case 'name':
      return '';
    case 'message':
      return '';
    case 'code':
      return !isPhone(value) || value.length !== 6 ? 'Incorrect code' : '';
    default:
      return 'The value should be email or phone';
  }
};

/**
 *
 * @param value "60"
 * @returns 1:0
 */
export const formatTime = time => {
  let seconds = time % 60;
  let minutes = Math.floor(time / 60);
  seconds = seconds.toString().length === 1 ? seconds : seconds;
  return minutes + ':' + seconds;
};

/**
 *
 * @param value 2021-08-30T10:55:13.636Z
 * @returns 1630320913636
 */
export const getTimeStamp = (value = '') => {
  if (value) {
    return Date.parse(value);
  } else {
    return Math.floor(new Date().getTime() / 1000);
  }
};

export const copyToClipboard = async (text, onSuccess, onError) => {
  navigator.clipboard.writeText(text).then(
    function() {
      onSuccess();
    },
    function(err) {
      onError(err);
    }
  );
};

export const checkInvalidUrl = url => {
  // Allow pages: /,/about-us,/community,/next
  const allowPages = (process.env.NEXT_PUBLIC_ALLOW_PAGES || '').split(',');

  // Allow params: /legal,?,screen=,action=
  const allowParams = (process.env.NEXT_PUBLIC_ALLOW_PARAMS || '').split(',');

  // / HOME
  // /legal LEGAL PAGES
  // /next REFERRAL PAGE
  return (
    !allowPages.find(item => url === item) &&
    !allowParams.find(item => url.indexOf(item) > -1)
  );
};

export const checkShowEvolvedUrl = url => {
  const allowPages = (process.env.NEXT_PUBLIC_APP_SHOW_EVOLVED || '').split(
    ','
  );

  return !allowPages.find(item => url.indexOf(item) > -1);
};

export const checkCommunityOrAboutUsUrl = url => {
  const allowPages = (
    process.env.NEXT_PUBLIC_APP_COMMUNITY_ABOUT_US || ''
  ).split(',');

  return allowPages.find(item => url.indexOf(item) > -1);
};

export const getReferralCodeFromUrl = (url, callBack) => {
  const referralCode = url.replace('/', '').split('?')[0];
  const hasInviterReferral = referralCode.length === 8;
  if (referralCode && hasInviterReferral) {
    // https://www.dogeking.com/a97cf83e?_kx=9S_I_pxcj20El1Mowj5Wvf2Ey0Ud50UtJrCHQjmsWqA%3D.SiDeKN
    if (url.indexOf('?') > -1) {
      callBack(referralCode);
    }
    return {
      referralCode,
      hasInviterReferral
    };
  }

  return null;
};

// Slice Array
export const sliceArr = (arr, n) => {
  const array = arr.slice();
  const chunks = [];
  while (array.length) chunks.push(array.splice(0, n));
  return chunks;
};

export const getOS = () => {
  const userAgent = isBrowser ? window.navigator.userAgent : '';
  const platform = isBrowser ? window.navigator.platform : '';
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (/(iPhone|iPod|iPad)/i.test(userAgent)) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  }

  return os;
};

export const getAppStoreLink = (os, appId) => {
  switch (os) {
    case 'Mac OS':
    case 'Windows':
      return `https://apps.apple.com/id/app/${appId}`;
    case 'iOS':
      return `itms-apps://apps.apple.com/id/app/${appId}`;
    default:
      return '#';
  }
};

export const getPlayStoreLink = (os, appId) => {
  switch (os) {
    case 'Windows':
    case 'Mac OS':
      return `https://play.google.com/store/apps/details?id=${appId}`;
    case 'Android':
      // return `market://details?id=${playStoreAppId}`;
      return `https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=${appId}&ddl=1&pcampaignid=web_ddl_1`;
    default:
      return '#';
  }
};

export const getAppLink = os => {
  switch (os) {
    case 'Mac OS':
      return `https://apps.apple.com/id/app/${appleStoreAppId}`;
    case 'iOS':
      return `itms-apps://apps.apple.com/id/app/${appleStoreAppId}`;
    case 'Windows':
      return `https://play.google.com/store/apps/details?id=${playStoreAppId}`;
    case 'Android':
      // return `market://details?id=${playStoreAppId}`;
      return `https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=${playStoreAppId}&ddl=1&pcampaignid=web_ddl_1`;
    default:
      return '#';
  }
};

export const formatPhoneNumber = value => {
  if (!value) {
    return value;
  }

  const phoneNumberLength = value.length;
  if (phoneNumberLength < 4) {
    return value;
  }

  if (phoneNumberLength >= 4 && phoneNumberLength < 10) {
    const phoneNumber = value.replace(/[^\d]/g, '');
    return phoneNumber.replace(/(\d{3})(\d)/, '($1)-$2');
  }

  const phoneNumber = value.replace(/[^\d]/g, '');
  return phoneNumber.replace(/(\d{3})(\d{3})(\d)/, '($1)-$2-$3');
};

export const getDownloadBtnInfos = data => {
  const newData = [...data];
  const playStoreLink = getPlayStoreLink(getOS(), playStoreAppId);
  const appStoreLink = getAppStoreLink(getOS(), appleStoreAppId);
  const appBtnIndex = newData.findIndex(item => item.key === 'apple');
  const playBtnIndex = newData.findIndex(item => item.key === 'play');

  newData[appBtnIndex].href = appStoreLink;
  newData[playBtnIndex].href = playStoreLink;

  return newData;
};

export const formatCurrency = number => {
  const suffixList = ['', 'k', 'm', 'b', 't'];
  const order = Math.floor(Math.log10(Math.abs(number)) / 3);
  const suffix = suffixList[order];

  return number / 10 ** (order * 3) + suffix;
};
