// Constants
import { ROUTES } from './routes';
import { IMAGES_CARD } from './images';
import { DOGEKING_TERMS } from './legals/terms';
import { DOGEKING_PRIVACY } from './legals/privacy';
import { DOGEKING_CONSUMER_ACCOUNT } from './legals/consumer-account';
import { DOGEKING_REWARDS } from './legals/rewards';
import { DOGEKING_REFERRAL_TERMS } from './legals/referral-terms';
import { DOGEKING_E_SIGN_CONSENT } from './legals/e-sign-consent';
import { DOGEKING_TERMS_OF_SERVICE } from './legals/terms-of-service';
import { DOGEKING_SOLID_PRIVACY } from './legals/solid-privacy';
import { DOGEKING_TRUST_CONSUMER_ACCOUNT } from './legals/ trust-consumer-account';
import { DOGEKING_EVOLVE_BANK_PRIVACY } from './legals/evolve-bank-privacy';
import { DOGEKING_EVOLVE_BANK_ELECTRONIC } from './legals/evolve-bank-electronic';
import { DOGEKING_EVOLVE_BANK_DEPOSIT } from './legals/evolve-bank-deposit';
// import { DOGEKING_VISA_CREDIT } from './legals/visa-credit';
import { DOGEKING_DEAL_REWARDS } from './legals/deal-rewards';

import {
  SITE_DATA,
  CONTACTS_INFO,
  ICONS_FEATURES
} from 'public/constants/common';
import {
  REFERRAL_CONTENTS,
  HOME_CONTENTS
} from 'public/constants/contents-constants';
import { ICONS_PARTNERS, TEAMS } from './images';

export const NAV_FOOTER = [
  // TODO: will fix this
  { label: 'Help & Support', href: 'https://help.dogecard.co/en/' },
  { label: 'Legal', href: ROUTES.LEGAL_PAGE },
  { label: 'Terms of Service', href: ROUTES.LEGAL_PLATFORM_TERMS_PAGE_LINK }
];

export const APP_NAME = {
  prideCard: 'PrideCard',
  dogeCard: 'DogeCard',
  terraCard: 'TerraCard',
  avalancheCard: 'AvalancheCard',
  choiceCard: 'ChoiceCard',
  liquidityCard: 'LiquidityCard',
  shibCard: 'ShibCard',
  causeCard: 'CauseCard',
  pawsCard: 'PawsCard'
};

export const NAV_HEADER = [
  { label: 'Benefits', href: ROUTES.HOME },
  { label: 'Community', href: ROUTES.COMMUNITY },
  { label: 'About us', href: ROUTES.ABOUT_US },
  { label: 'FAQ', href: ROUTES.FAQ }
];

export const LINKS_TO = {
  getEvolved: 'https://www.getevolved.com/'
};

export const SOCIAL_ICONS = [
  {
    iconPath: '/icons/socials/facebook.svg',
    label: 'facebook'
  },
  {
    iconPath: '/icons/socials/twitter.svg',
    label: 'twitter'
  },
  {
    iconPath: '/icons/socials/snap.svg',
    label: 'snapchat'
  },
  {
    iconPath: '/icons/socials/whatsapp.svg',
    label: 'whatsapp'
  }
];

export const CARDS = [
  {
    id: 0,
    url: IMAGES_CARD.cardVisaBlack,
    label: 'Visa Card Black',
    width: 263,
    height: 386,
    value: 'black'
  },
  {
    id: 1,
    url: IMAGES_CARD.cardVisa,
    label: 'Visa Card',
    width: 263,
    height: 386,
    value: 'gold'
  }
];

export const CARDS_ORDERS = [
  {
    className: 'check-ordered',
    title: 'Joined waitlist'
  },
  {
    className: 'check-send',
    title: 'Send to friends'
  },
  {
    className: 'check-get-card',
    title: `Get ${SITE_DATA.appName}`
  }
];

export const FRIENDS_GET = [
  {
    className: 'get-ordered',
    title: coin => `$${coin} ${REFERRAL_CONTENTS.inviteContent}`,
    id: 1
  },
  // {
  //   className: 'get-send',
  //   title: () => 'Double Doge rewards',
  //   id: 2
  // },
  {
    className: 'get-virtual',
    title: () => 'Move up the waitlist',
    id: 2
  }
  // {
  //   className: 'get-physical',
  //   title: '$5 off your next Chipotle purchase*'
  // }
];

export const CARD_BENEFITS = [
  'Free Checking account, FDIC insured. No credit checks',
  'Dogecoin back on every purchase*',
  'Get paid early with Direct Deposit',
  'No overdraft fees',
  // HIDE based on change 20211104
  // 'Visa fraud monitoring',
  'Live chat support'
];

export const CONNECTION = {
  EMAIL: 'email',
  SMS: 'sms'
};

export const TERM_OF_SERVICE_LIST = [
  // 1
  {
    className: 'item-term',
    title: `${SITE_DATA.appName} Terms`,
    href: ''
  },
  // 2
  {
    className: 'item-policy',
    title: `${SITE_DATA.appName} Privacy Policy`,
    href: ROUTES.LEGAL_PRIVACY_PAGE
  },
  // 3
  {
    className: 'item-consent',
    title: 'E-sign Consent',
    href: ROUTES.LEGAL_ELECTRONIC_PAGE
  },
  // 4
  {
    className: 'item-consumer',
    title: `${SITE_DATA.appName} Consumer Account Agreement`,
    href: ROUTES.LEGAL_CONSUMER_ACCOUNT_PAGE
  },
  // 5
  {
    className: 'item-referrals',
    title: 'Referral Terms',
    href: ROUTES.LEGAL_REFERRALS_PAGE
  },
  // 6
  {
    className: 'item-rewards',
    title: 'Rewards Terms and Conditions',
    href: ROUTES.LEGAL_REWARDS_PAGE
  },
  // HIDE based on change 20211104
  // {
  //   className: 'item-visa-credit',
  //   title: 'Visa Credit Card Agreement',
  //   href: ROUTES.LEGAL_VISA_CREDIT_PAGE
  // },
  // 7
  {
    className: 'item-platform-terms',
    title: 'Platform Terms of Service',
    href: ROUTES.LEGAL_PLATFORM_TERMS_PAGE
  },
  // 8
  {
    className: 'item-solid-privacy',
    title: 'Solid Privacy Policy',
    href: ROUTES.LEGAL_SOLID_PRIVACY_PAGE
  },
  // 9
  {
    className: 'item-bank-trust-consumer',
    title: 'Evolve Bank and Trust Consumer Account Terms',
    href: ROUTES.LEGAL_BANK_TRUST_CONSUMER_PAGE
  },
  // 10
  {
    className: 'item-trust-privacy',
    title: 'Evolve Bank Privacy Policy',
    href: ROUTES.LEGAL_BANK_TRUST_POLICY_PAGE
  },
  // 11
  {
    className: 'item-electronic',
    title: 'Evolve Bank Electronic Communications Consent',
    href: ROUTES.LEGAL_EVOLVE_E_CONSENT_PAGE
  },
  // 12
  {
    className: 'item-deposit',
    title: 'Evolve Bank Deposit Agreement',
    href: ROUTES.LEGAL_EVOLVE_DEPOSIT_PAGE
  },
  // 13
  {
    className: 'item-deal',
    title: `${SITE_DATA.firstAppName} Deal Rewards Terms`,
    href: ROUTES.LEGAL_DEAL_REWARDS_PAGE
  }
];

export const ALL_LEGAL_PAGES_CONTENT = {
  // 1
  legal: {
    title: `${SITE_DATA.appName} Terms`,
    content: DOGEKING_TERMS,
    breadcrumb: `${SITE_DATA.appName} Terms`
  },
  // 2
  'privacy-policy': {
    title: 'Privacy Policy',
    content: DOGEKING_PRIVACY,
    breadcrumb: 'Privacy Policy'
  },
  // 3
  'e-sign-consent': {
    title: 'E-sign Consent',
    content: DOGEKING_E_SIGN_CONSENT,
    breadcrumb: 'E-sign Consent'
  },
  // 4
  'consumer-account': {
    title: 'Consumer Account Agreement',
    content: DOGEKING_CONSUMER_ACCOUNT,
    breadcrumb: 'Consumer account Agreement'
  },
  // 5
  'referral-terms': {
    title: 'Referral Terms',
    content: DOGEKING_REFERRAL_TERMS,
    breadcrumb: 'Referral Terms'
  },
  // 6
  rewards: {
    title: 'Rewards Terms and Conditions',
    content: DOGEKING_REWARDS,
    breadcrumb: 'Rewards Terms & Conditions'
  },
  // 'visa-credit': {
  //   title: 'Visa Credit Card Agreement',
  //   content: DOGEKING_VISA_CREDIT,
  //   breadcrumb: 'Visa Credit Card Agreement'
  // },
  // 7
  'platform-terms': {
    title: 'Platform Terms of Service',
    content: DOGEKING_TERMS_OF_SERVICE,
    breadcrumb: 'Platform Terms of Service'
  },
  // 8
  'solid-privacy': {
    title: 'Solid Privacy Policy',
    content: DOGEKING_SOLID_PRIVACY,
    breadcrumb: 'Solid Privacy Policy'
  },
  // 9
  'bank-trust-consumer': {
    title: 'Evolve Bank and Trust Consumer Account Terms',
    content: DOGEKING_TRUST_CONSUMER_ACCOUNT,
    breadcrumb: 'Evolve Bank and Trust Consumer Account Terms'
  },
  // 10
  'bank-trust-policy': {
    title: 'Evolve Bank Privacy Policy',
    content: DOGEKING_EVOLVE_BANK_PRIVACY,
    breadcrumb: 'Evolve Bank Privacy Policy'
  },
  // 11
  'evolve-e-consent': {
    title: 'Evolve Bank Electronic Communications Consent',
    content: DOGEKING_EVOLVE_BANK_ELECTRONIC,
    breadcrumb: 'Evolve Electronic Consent'
  },
  // 12
  'evolve-deposit': {
    title: 'Evolve Bank Deposit Agreement',
    content: DOGEKING_EVOLVE_BANK_DEPOSIT,
    breadcrumb: 'Evolve Bank Deposit Agreement'
  },
  // 13
  'deal-rewards': {
    title: 'Solid Fintech Program Rewards',
    content: DOGEKING_DEAL_REWARDS,
    breadcrumb: 'Solid Fintech Program Rewards'
  }
};

export const CONTACTS_INFORMATION = {
  mainTitle: 'Contact Information',
  subTitle:
    'Fill out the form and our Team will get back to you within 24 hours',
  infoDetail: [
    {
      info: CONTACTS_INFO?.phoneNumber,
      class: 'phone-icon',
      key: 'phone'
    },
    { info: '9:00 - 17:00', class: 'time-icon', key: 'time' },
    {
      info: CONTACTS_INFO?.address,
      class: 'map-icon',
      key: 'map'
    },
    { info: 'Monday - Friday', class: 'calendar-icon', key: 'calendar' }
  ]
};

export const TEAMS_INFO = [
  {
    imagePath: TEAMS.ryan,
    imageName: 'Ryan',
    imageWidth: 240,
    imageHeight: 240,
    nameMember: 'Ryan Fujiu, Founder',
    key: 'ryan',
    arcDeg: {
      start: '85',
      end: '45'
    },
    info:
      'Ryan has been working in consumer products for over 20 years. Most recently he was Chief Product Officer at Bird. Prior to that he was Head of Driver Growth at Uber, and also Head of Product at Lyft. He was an early teammate creating Micro-Mobility (Bird), On Demand Food Delivery (Ubereats), and Rideshare (Lyft).'
  },
  {
    imagePath: TEAMS.chloe,
    imageName: 'Chloe',
    imageWidth: 240,
    imageHeight: 240,
    nameMember: 'Chloe Madison, Marketing Lead',
    key: 'chloe',
    arcDeg: {
      start: '220',
      end: '155'
    },
    info:
      'Chloe has over seven years of experience in digital marketing. She grew up in Los Angeles alongside the very first influencers as they pioneered a new form of marketing on social media. Chloe knows how to speak to, and deeply connect with newer generations and has breadth of knowledge across all social media platforms and trends that she leans into for her marketing strategies. <p class="pt-5">Chloe started investing in Dogecoin in January 2021 and is a huge fan of the community and culture it nurtures.</p>'
  },
  {
    imagePath: TEAMS.rick,
    imageName: 'Rick',
    imageWidth: 240,
    imageHeight: 240,
    nameMember: 'Rick Bachman, Compliance Officer',
    key: 'rick',
    arcDeg: {
      start: '25',
      end: '120'
    },
    info:
      'Rick is the Founder and Managing Member of Scale Consulting. Rick has over 25 years of industry experience, building compliance programs at large banks like Wells Fargo and Bank of the West. Rick is the former Head of Compliance for LendingHome, a FinTech startup specializing in hard money real estate lending. Since starting Scale Consulting in 2017, he has helped several well known FinTech firms, including Blend, Chime, Brex, Mercury, One, Orum and over 80 others across a broad array of financial services, including consumer and business lending, credit cards and consumer and commercial deposits, to design and implement their programs. Rick is a Certified Regulatory Compliance Manager (CRCM) and has an MBA from Webster University.'
  },
  {
    imagePath: TEAMS.roman,
    imageName: 'Roman',
    imageWidth: 240,
    imageHeight: 240,
    nameMember: 'Roman Ozmo, Head Designer',
    key: 'roman',
    arcDeg: {
      start: '205',
      end: '153'
    },
    info:
      'I’m a UI UX experienced designer with more than 500 projects designed for the last 9 years. My main focus is designing beautiful interfaces & intuitive interactions. I own around 100 different coins, of which 10% is Dogecoin, I also own a few NFTs. I also like to shoot macro photos and bake bread.'
  },
  {
    imagePath: TEAMS.glauber,
    imageName: 'Glauber',
    imageWidth: 240,
    imageHeight: 240,
    nameMember: 'Glauber Contessoto, Creative Director',
    key: 'glauber',
    arcDeg: {
      start: '125',
      end: '105'
    },
    info:
      'Glauber discovered Dogecoin in January 2021 and immediately fell in love with the community and what it stood for. He saw the incredible potential Dogecoin had to change the monetary system that we have in place today and instantly wanted to invest in this coin that he saw as the future. Glauber  invested his life savings into Dogecoin and decided to make it his mission from that day forward to help bring about awareness and mass adoption to Dogecoin and push it forward. He came to realize it was truly “the people’s crypto” and held onto it through the ups and downs because he saw it as the future. Glauber was contacted about this project about 6 months ago and has been an integral part in the branding and marketing. He was the Senior Content Creator at HipHopDX and ran the video production team as well as branding for their Youtube channel. Prior to that he worked at a start-up called MyDiveo and ran the marketing team and video production as well.',
    addressTo: [
      {
        href: 'https://twitter.com/prothedoge',
        name: 'Twitter',
        key: 'twitter'
      },
      {
        href: 'https://www.youtube.com/channel/UCvRP2JVdPZxPqdLFsv_VTmQ',
        name: 'YouTube',
        key: 'youtube'
      }
    ]
  },
  {
    imagePath: TEAMS.jason,
    imageName: 'Jason',
    imageWidth: 240,
    imageHeight: 240,
    nameMember: 'Jason Spencer, Recruiter',
    key: 'jason',
    arcDeg: {
      start: '77',
      end: '225'
    },
    info:
      'Jason Spencer is a Sr. Technical Recruiter who has over ten years of recruiting experience with both agency and corporate recruiting. Although his specialty is technical recruiting, Jason has also recruited a myriad of positions ranging from accountants to CEOs. Jason became a recruiter because he enjoys helping others advance their career and/or simply make their own lives better. <p class="pt-5">Jason lives in Southern California with his wife and two dogs. He is a voracious reader, enjoys theater (Rent is his current favorite), and believes PC Gaming is far superior to consoles.</p>'
  },
  {
    imagePath: TEAMS.emezie,
    imageName: 'Emezie',
    imageWidth: 240,
    imageHeight: 240,
    nameMember: 'Kc Emezie, Lead Engineer',
    key: 'emezie',
    arcDeg: {
      start: '45',
      end: '165'
    },
    info:
      'KC is Liquidity Financial’s Lead Engineer. Before Liquidity, KC led engineering for a small startup based out of southern California.',
    addressTo: [
      {
        href: 'https://www.linkedin.com/in/kc-emezie-b5b84982/',
        name: 'LinkedIn',
        key: 'linkedin'
      }
    ]
  },
  // {
  //   imagePath: TEAMS.yadiyah,
  //   imageName: 'Yadiyah',
  //   imageWidth: 240,
  //   imageHeight: 240,
  //   nameMember: 'Yadiyah Xaedra, Community Manager',
  //   arcDeg: {
  //     start: '115',
  //     end: '195'
  //   },
  //   info: `Yadiyah is our Community Manager and is responsible for engaging through social media channels to ensure that the community's voices, opinions, and feedback are heard by our team. She has loved Dogecoin since January 2021. Yadiyah worked as a transcriptionist after college with a company called CopyTalk Business Services, where she was introduced to the stock market. After her time as a scribe, she began learning about the cryptocurrency market and the blockchain, and has been involved with these sectors ever since. She has worked as a freelancer for various projects and typically provides organizational support, consultation, and community relations.`
  // },
  {
    imagePath: TEAMS.andrew,
    imageName: 'Andrew',
    imageWidth: 240,
    imageHeight: 240,
    nameMember: 'Andrew Erskine, Head of Legal',
    key: 'andrew',
    arcDeg: {
      start: '120',
      end: '60'
    },
    info:
      'Andrew is a partner in the Technology Companies Group at Orrick, advising high-growth companies, their founders, and the investors that support them. Andrew joined the Liquidity team in July of 2021.'
  }
];

export const PARTNERS_INFO = [
  {
    iconPath: ICONS_PARTNERS.evolveBank,
    iconName: 'Evolve',
    iconWidth: 130,
    iconHeight: 50,
    titleHead: 'Evolve Bank and Trust',
    info:
      'For 95 years, Evolve Bank and Trust have served communities, large and small. DogeCard is proud to be backed by one of the leading, full-service financial organizations with offices throughout the country.',
    iconhref: '#'
  },
  {
    iconPath: ICONS_PARTNERS.solid,
    iconName: 'Solid',
    iconWidth: 130,
    iconHeight: 43,
    titleHead: 'Solid',
    info:
      'Solid powers a fully integrated & compliant suite of FinTech services for DogeCard customers.'
  },
  {
    iconPath: ICONS_PARTNERS.zero,
    iconName: 'Zero',
    iconWidth: 160,
    iconHeight: 47,
    titleHead: 'Zero Hash',
    info:
      'Zero Hash is an institutional settlement utility for the digital asset space. Offering a suite of institutional-grade post-trade settlement services, Zero Hash is the leading multi-platform and multi-custodial settlement.'
  },
  {
    iconPath: ICONS_PARTNERS.visa,
    iconName: 'Visa',
    iconWidth: 120,
    iconHeight: 39,
    titleHead: 'Visa',
    info: `The world leader in credit cards. Visa is accepted at millions of locations across the United States. Visa offers our user's liability Insurance and controls.`
  }
];

export const FEATURES_INFO = [
  {
    iconPath: ICONS_FEATURES.fdicMember,
    iconName: 'Member',
    iconWidth: 141,
    iconHeight: 87,
    info: '$250,000 FDIC insured Evolve Bank & Trust',
    iconLinkTo: 'https://www.getevolved.com/',
    key: 'member'
  },
  {
    iconPath: ICONS_FEATURES.union,
    iconName: 'Union',
    iconWidth: 82,
    iconHeight: 90,
    info: 'Bank-grade encryption and authentication',
    key: 'union'
  },
  {
    iconPath: HOME_CONTENTS.featureIconPath,
    iconName: HOME_CONTENTS.protection,
    iconWidth: HOME_CONTENTS.iconWidth,
    iconHeight: HOME_CONTENTS.iconHeight,
    info: HOME_CONTENTS.featureInfo,
    key: 'visa'
  }
];

export const COPY_MESSAGE = 'Copied to clipboard!';
