import React from 'react';
import LazyLoadImage from '../LazyLoadImage';

export const SplashScreen = () => (
  <div
    className={`w-full h-screen flex mt-0 items-center justify-center bg-bg-main`}
  >
    <div className="text-white h-20 rounded-full overflow-hidden">
      <LazyLoadImage
        alt="logo"
        src={'/logos/logo.png'}
        width={80}
        height={80}
      />
    </div>
  </div>
);
