/* eslint-disable no-underscore-dangle */
import axios, { AxiosResponse } from 'axios';
import React, { createContext, useEffect, useReducer, ReactNode } from 'react';
import { SplashScreen } from '../components/SplashScreen';
import { CRM_EVENT } from '../constants/crm-event';
import { getAuthAccount, linkAuthAccount } from '../utils/api';
import { getTimeStamp } from '../utils/helper';
import { segmentSendEventData, segmentTrackEventData } from '../utils/segment';
import {
  deleteStorage,
  getStorage,
  resetAccountData,
  setStorage,
  STORAGE_KEYS
} from '../utils/storage';
import { SITE_DATA } from 'public/constants/common';

interface AccountDataType {
  userId: string;
  email: string;
  emailVerified: boolean;
  phone: string;
  phoneVerified: boolean;
  isSignin: boolean;
  referralCode: string;
  pushNotification: string;
  name: string;
  metadata: {
    cardType: string;
  };
  inviterInfo: {
    referralCode: string;
    email: string;
    phone: string;
    name: string;
    userId: string;
    pushNotification: string;
    referralBonusCoins: number;
    referralRewardAmount: number;
  };
  referralBonusCoins: number;
  referralRewardAmount: number;
  registeredAt: string;
}

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  userMetadata: null | any;
  type: string;
}

interface AuthContextValue extends AuthState {
  logout: () => void;
  logIn: (data: any) => void;
  linkAccount: (data: any) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    userMetadata: null | any;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    isAuthenticated: boolean;
    userMetadata: null | any;
  };
};

type LoginCallbackAction = {
  type: 'LOGIN_CALLBACK';
  payload: {
    callback: () => void;
  };
};

type LinkAccountAction = {
  type: 'LINK_ACCOUNT';
  payload: any;
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | LoginCallbackAction
  | LinkAccountAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  userMetadata: null,
  type: ''
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALIZE': {
      const { isAuthenticated, userMetadata } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        userMetadata,
        type: action.type
      };
    }

    case 'LOGIN': {
      const { userMetadata } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        isInitialized: true,
        userMetadata,
        type: action.type
      };
    }

    case 'LOGIN_CALLBACK': {
      return {
        ...state,
        type: action.type
      };
    }

    case 'LINK_ACCOUNT': {
      return {
        ...state,
        type: action.type
      };
    }

    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        userMetadata: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  logout: () => {},
  logIn: (data: any) => {},
  linkAccount: (email: any) => {}
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const getUserDataAndSetHeader = () => {
    const userMetadata = getStorage(STORAGE_KEYS.USER_METADATA);
    const idToken = getStorage(STORAGE_KEYS.AUTH_TOKEN);
    if (idToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
    }
    return !!userMetadata;
  };

  const logout = async () => {
    dispatch({
      type: 'LOGOUT'
    });
    resetAccountData();
    deleteStorage();
  };

  const logIn = async data => {
    try {
      const response: AxiosResponse = await getAuthAccount({
        userId: data.userId
      });
      const dataResponse: AccountDataType = response.data;

      if (!dataResponse.isSignin) {
        const userName = dataResponse.name || dataResponse.email || '';
        // Event for register user
        new Promise(resolve => {
          segmentSendEventData({
            cardType: dataResponse.metadata.cardType || '',
            email: dataResponse.email || '',
            emailVerified: dataResponse.emailVerified || false,
            name: userName,
            phone: dataResponse.phone || '',
            phoneVerified: dataResponse.phoneVerified || false,
            referralCode: dataResponse.referralCode,
            referralLink: dataResponse.referralCode
              ? `${SITE_DATA.domain}${dataResponse.referralCode}`
              : '',
            referredBy:
              dataResponse.inviterInfo?.email ||
              dataResponse.inviterInfo?.phone ||
              '',
            referralBonus: dataResponse.referralBonusCoins,
            referralReward: dataResponse.referralRewardAmount,
            signupTimeStamp: getTimeStamp(dataResponse.registeredAt),
            userId: dataResponse.userId || data.userId,
            userName: userName,
            pushNotification: dataResponse.pushNotification,
            modeRun: process.env.NEXT_PUBLIC_ENV
          });
          // Do next step
          resolve(true);
        }).then(() => {
          if (!!dataResponse.inviterInfo) {
            const inviterInfoName =
              dataResponse.inviterInfo?.name ||
              dataResponse.inviterInfo?.email ||
              '';
            // Event for inviter user
            new Promise(resolve => {
              segmentSendEventData({
                cardType: dataResponse.metadata.cardType || '',
                email: dataResponse.inviterInfo?.email || '',
                emailVerified: !!dataResponse.inviterInfo?.email,
                isReferral: true,
                name: inviterInfoName,
                phone: dataResponse.inviterInfo?.phone || '',
                phoneVerified: !!dataResponse.inviterInfo?.phone,
                referralCode: dataResponse.inviterInfo.referralCode || '',
                referralLink: dataResponse.inviterInfo.referralCode
                  ? `${SITE_DATA.domain}${dataResponse.inviterInfo.referralCode}`
                  : '',
                referredBy:
                  dataResponse.inviterInfo?.email ||
                  dataResponse.inviterInfo?.phone ||
                  '',
                referralBonus: dataResponse.inviterInfo?.referralBonusCoins,
                referralReward: dataResponse.inviterInfo?.referralRewardAmount,
                referralTo: dataResponse.name || '',
                signupTimeStamp: getTimeStamp(dataResponse.registeredAt),
                userId: dataResponse.inviterInfo?.userId,
                userName: inviterInfoName,
                pushNotification: dataResponse.inviterInfo?.pushNotification,
                modeRun: process.env.NEXT_PUBLIC_ENV
              });
              // Do next step
              // segmentTrackEventData(CRM_EVENT.USER_REFERRAL, {
              //   email: dataResponse.inviterInfo?.email
              // });

              resolve(true);
            }).then(() => {
              // Sign up with Referral
              segmentTrackEventData(CRM_EVENT.SIGNED_UP, {
                referral: CRM_EVENT.REFERRAL_SIGN_UP_DESCRIPTION
              });
            });
          } else {
            // Sign up without Referral
            segmentTrackEventData(CRM_EVENT.SIGNED_UP, {
              organic: CRM_EVENT.ORGANIC_SIGN_UP_DESCRIPTION
            });
          }
        });
      } else {
        const userName = dataResponse.name || dataResponse.email || '';
        segmentSendEventData({
          cardType: dataResponse.metadata.cardType || '',
          email: dataResponse.email || '',
          emailVerified: dataResponse.emailVerified || false,
          name: userName,
          phone: dataResponse.phone || '',
          phoneVerified: dataResponse.phoneVerified || false,
          userId: dataResponse.userId,
          userName: userName,
          modeRun: process.env.NEXT_PUBLIC_ENV
        });
        segmentTrackEventData(CRM_EVENT.LOGGED_IN, {
          cardType: dataResponse.metadata.cardType || '',
          email: dataResponse.email || '',
          emailVerified: dataResponse.emailVerified || false,
          name: userName,
          phone: dataResponse.phone || '',
          phoneVerified: dataResponse.phoneVerified || false,
          referralCode: dataResponse.referralCode,
          referralLink: dataResponse.referralCode
            ? `${SITE_DATA.domain}${dataResponse.referralCode}`
            : '',
          referredBy:
            dataResponse.inviterInfo?.email ||
            dataResponse.inviterInfo?.phone ||
            '',
          referralBonus: dataResponse.referralBonusCoins,
          referralReward: dataResponse.referralRewardAmount,
          signupTimeStamp: getTimeStamp(dataResponse.registeredAt),
          userId: dataResponse.userId,
          userName: userName,
          pushNotification: dataResponse.pushNotification,
          modeRun: process.env.NEXT_PUBLIC_ENV
        });
      }

      // Save data into storage
      setStorage(STORAGE_KEYS.USER_METADATA, JSON.stringify(dataResponse));
      setStorage(STORAGE_KEYS.REFERRAL_CODE, dataResponse.referralCode);

      // Set header for axios on authorization
      getUserDataAndSetHeader();

      await dispatch({
        type: 'LOGIN',
        payload: {
          userMetadata: dataResponse,
          isAuthenticated: true
        }
      });
    } catch (error) {
      console.error(error);
      data.onError(error);
    }
  };

  const linkAccount = async (data: any) => {
    try {
      await linkAuthAccount({
        email: data.email,
        phone: '',
        connection: 'email'
      });

      const userMetadata = JSON.parse(getStorage(STORAGE_KEYS.USER_METADATA));
      setStorage(
        STORAGE_KEYS.USER_METADATA,
        JSON.stringify({ ...userMetadata, email: data.email })
      );
      setStorage(STORAGE_KEYS.EMAIL, '');

      data.onSuccess();

      segmentSendEventData({
        cardType: userMetadata.metadata.cardType || '',
        email: data.email,
        emailVerified: userMetadata.emailVerified || false,
        name: userMetadata.name || '',
        phone: userMetadata.phone || '',
        phoneVerified: userMetadata.phoneVerified || false,
        referralCode: userMetadata.referralCode,
        referralLink: userMetadata.referralCode
          ? `${SITE_DATA.domain}${userMetadata.referralCode}`
          : '',
        referredBy:
          userMetadata.inviterInfo?.email ||
          userMetadata.inviterInfo?.phone ||
          '',
        referralBonus: userMetadata.referralBonusCoins,
        referralReward: userMetadata.referralRewardAmount,
        signupTimeStamp: getTimeStamp(userMetadata.registeredAt),
        userId: userMetadata.userId,
        userName: userMetadata.name || '',
        pushNotification: userMetadata.pushNotification,
        modeRun: process.env.NEXT_PUBLIC_ENV
      });
      segmentTrackEventData(CRM_EVENT.SIGNED_UP, {});
    } catch (error) {
      console.error(error);
      data.onError(error);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      if (getUserDataAndSetHeader()) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            userMetadata: getStorage(STORAGE_KEYS.USER_METADATA),
            isAuthenticated: true
          }
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            userMetadata: null,
            isAuthenticated: false
          }
        });
      }
    };
    if (!state.isInitialized) {
      initialize();
    }
  }, []);

  if (!state.isInitialized) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout,
        logIn,
        linkAccount
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
