import { ROUTES } from '../routes';
import { SITE_DATA } from 'public/constants/common';
import { LEGAL_STYLES } from 'public/constants/styles-constants';

export const DOGEKING_PRIVACY = [
  {
    text: `${SITE_DATA.appName} Privacy Notice`
  },
  {
    className: 'italic-text',
    text: `Last Updated: 9/21/2021`
  },
  {
    className: '',
    text: `Welcome! We are ${SITE_DATA.appName} and offer mobile banking and credit card, where you get Doge back on every purchase. This Privacy Notice explains how Liquidity Financial, Inc. (“${SITE_DATA.appName}”, “we”, “us” or “our”) collects, uses, discloses, and otherwise processes Personal Information (as defined below) when you access and use our products and services that are made available by ${SITE_DATA.appName} (“Services”) through our <a class="cursor-pointer text-yellow text-highlight" href="${ROUTES.HOME}">Website</a> (the “Site”), our mobile applications (the “App”), and social media sites (collectively, the “Sites”). It does not address our privacy practices relating to ${SITE_DATA.appName} employees and other personnel.`
  },
  {
    className: '',
    text: 'Click on the links below to jump to each section:'
  },
  {
    className: 'sub-text',
    text:
      '<a href="#privacy-policy#personal-infomation">I. What is Personal Information?</a>'
  },
  {
    className: 'sub-text',
    text:
      '<a href="#privacy-policy#our-collection">II. Our Collection of Personal Information</a>'
  },
  {
    className: 'sub-text',
    text:
      '<a href="#privacy-policy#our-use">III. Our Use of Personal Information</a>'
  },
  {
    className: 'sub-text',
    text:
      '<a href="#privacy-policy#our-disclosure">IV. Our Disclosure of Personal Information</a>'
  },
  {
    className: 'sub-text',
    text:
      '<a href="#privacy-policy#control-over">V. Control Over Your Information</a>'
  },
  {
    className: 'sub-text',
    text:
      '<a href="#privacy-policy#third-party"> VI. Third Party Tracking and Online Advertising</a>'
  },
  {
    className: 'sub-text',
    text:
      '<a href="#privacy-policy#our-commitment">VII. Our Commitment to Data Security</a>'
  },
  {
    className: 'sub-text',
    text:
      '<a href="#privacy-policy#children-personal">VIII. Children’s Personal Information</a>'
  },
  {
    className: 'sub-text',
    text:
      '<a href="#privacy-policy#services">IX. Links to Third-Party Websites or Services</a>'
  },
  {
    className: 'sub-text',
    text:
      '<a href="#privacy-policy#notice">X. Updates to This Privacy Notice</a>'
  },
  {
    className: 'sub-text',
    text: '<a href="#privacy-policy#contact-us">XI. Contact Us</a>'
  },
  {
    className: 'roman-list-one',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    text:
      '<a id="privacy-policy#personal-infomation" class="font-bold">What is Personal Information?</a>'
  },
  {
    className: '',
    text:
      'When we use the term “Personal Information” in this Privacy Notice, we mean information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, to an individual. It does not include aggregated or deidentified information that is maintained in a form that is not reasonably capable of being associated with or linked to an individual.'
  },
  {
    className: 'roman-list-two',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text:
      '<a id="privacy-policy#our-collection" class="font-bold">Our Collection of Personal Information</a>'
  },
  {
    className: '',
    text:
      'Sometimes we collect Personal Information automatically when an individual interacts with our Sites and sometimes we collect Personal Information directly from an individual. At times, we may collect Personal Information about an individual from other sources and third parties, even before our first direct interaction.'
  },
  {
    className: 'underline-text',
    text:
      'Personal Information Collected from Site Visitors and Account Holders'
  },
  {
    className: '',
    text:
      'We collect the following Personal Information submitted to us by visitors to our Site or when you use our Services – for example, you apply for or open an account, when you request information, complete a survey, or when you participate in a promotion. '
  },
  {
    className: '',
    text: `Moreover, as a company engaged in financial transactions, ${SITE_DATA.appName} both receives and transmits data to consumer reporting agencies. We partner with these agencies to assess your creditworthiness and to prevent fraud and identity theft. This is done as part of our regular underwriting, fraud prevention and loan servicing processes.`
  },
  {
    className: '',
    text:
      'In such cases, we may ask you to provide us with one or more of the following pieces of information:'
  },
  {
    className: 'sub-text',
    text:
      '- Contact Information, including name, email address, mailing address, company, phone number.'
  },
  {
    className: 'sub-text',
    text:
      '- Account Profile Information, including name, job title, company, employer, email address, username and password, profile information and any other information you provide to us.'
  },
  {
    className: 'sub-text',
    text:
      '- Account Application Information, including date of birth, credit and income information. '
  },
  {
    className: 'sub-text',
    text:
      '- Consumer Reports Information, including credit score, credit inquiries, late payments and collection actions occurring on open and closed credit accounts; information regarding public records of bankruptcy, judgements, tax liens, and other payment statuses; transaction history, assets, and account balances; and correcting information maintained by the credit bureaus. '
  },
  {
    className: 'sub-text',
    text:
      '- Contact Information, including name, email address, mailing address, company, phone number.'
  },
  {
    className: 'sub-text',
    text:
      '- Identifiers, including social security number, account number(s), drivers license number (or comparable) or other information that identifies you for ordinary business purposes.'
  },
  {
    className: 'sub-text',
    text:
      '- Access Authorization, such as username, alias, PIN and passcode and security questions and answers.'
  },
  {
    className: 'sub-text',
    text:
      '- Inquiry Information, including information provided in custom messages sent through the forms or contact information provided on our Site. '
  },
  {
    className: 'sub-text',
    text:
      '- Job Applicant-Related Information, including your name, contact and demographic information, educational and work history, employment interests, information obtained during interviews and any other information you choose to provide. '
  },
  {
    className: 'sub-text',
    text:
      '- Survey, contest, Sweepstakes, or Other Promotion Information, including information provided in any questions submitted through surveys, content of any testimonials, or information submitted through your participation in one of our contests, sweepstakes, or promotions.'
  },
  {
    className: 'sub-text',
    text:
      '- Preferences, including newsletter preferences and communication preferences.'
  },
  {
    className: 'sub-text',
    text:
      '- Financial Data, including bank account number, debit card information, payment card type, billing address, phone number, and email, account balance, transactional histories and other financial information.'
  },
  {
    className: 'sub-text',
    text:
      '- Phone Communications, including any information disclosed during our calls. In compliance with applicable laws and with your consent (where required by law), we may monitor and record phone communications. '
  },
  {
    className: 'underline-text',
    text: 'Personal Information Automatically Collected'
  },
  {
    className: '',
    text:
      'As is true of most digital platforms, we and our third-party providers may also collect Personal Information from an individual’s device, browsing actions and site usage patterns automatically when visiting or interacting with our Site, which may include: '
  },
  {
    className: 'sub-text',
    text:
      '- log data (such as internet protocol (IP) address, operating system, browser type, browser id, the URL entered and the referring page/campaign, date/time of visit, the time spent on our Site and any errors that may occur during the visit to our Site),'
  },
  {
    className: 'sub-text',
    text:
      '- analytics data (such as the electronic path taken to our Site, through our Site and when exiting our Site, as well as usage and activity on our Site), and'
  },
  {
    className: 'sub-text',
    text:
      '- location data (such as general geographic location based on the log data we or our third-party providers collect).  '
  },
  {
    className: '',
    text:
      'We and our third-party providers may use (i) cookies or small data files that are stored on an individual’s computer and (ii) other, related technologies, such as web beacons, pixels, embedded scripts, location-identifying technologies and logging technologies (collectively, “cookies”) to automatically collect this Personal Information. For example, our Site uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”), to collect and view reports about the traffic on our Site. More information about the use of Google Analytics for these analytical and non-advertising purposes can be obtained by visiting Google’s privacy policy here and Google’s currently available opt-out options are available here. '
  },
  {
    className: '',
    text:
      'To manage cookies, an individual may change their browser settings to: (i) notify them when they receive a cookie, so the individual can choose whether or not to accept it; (ii) disable existing cookies; or (iii) automatically reject cookies.  Please note that doing so may negatively impact an individual’s experience using our Site, as some features and offerings may not work properly or at all.  Depending on an individual’s device and operating system, the individual may not be able to delete or block all cookies. In addition, if an individual wants to reject cookies across all browsers and devices, the individual will need to do so on each browser on each device they actively use. An individual may also set their email options to prevent the automatic downloading of images that may contain technologies that would allow us to know whether they have accessed our email and performed certain functions with it. '
  },
  {
    className: '',
    text:
      'We and our third-party partners may also use cookies and tracking technologies for advertising purposes. For more information about tracking technologies, please see Third-party tracking and online advertising below.'
  },
  {
    className: 'underline-text',
    text: 'Personal Information from Third Parties'
  },
  {
    className: '',
    text:
      'We also obtain Personal Information from third parties; which we often combine with Personal Information we collect either automatically or directly from an individual.'
  },
  {
    className: '',
    text:
      'We may receive the same categories of Personal Information as described above from the following third parties:'
  },
  {
    className: 'sub-text',
    text:
      '- Social Media: When an individual interacts with our Sites through various social media networks, such as when someone “Likes” us on Facebook or follows us or shares our content on Google, Facebook, Twitter, or other social networks, or posts content on any of our social media sites, we may receive some information about individuals that they permit the social network to share with third parties. The data we receive is dependent upon an individual’s privacy settings with the social network, and may include your username, password, name, email address, and profile picture. Individuals should always review and, if necessary, adjust their privacy settings on third-party websites and social media networks and services before sharing information and/or linking or connecting them to other services.'
  },
  {
    className: 'sub-text',
    text:
      '- Your Employer / Company: If you engage in our Sites through your employer, or company, we may receive your information from the company such as name and contact information.'
  },
  {
    className: 'sub-text',
    text:
      '- Service Providers: Our service providers that perform services solely on our behalf, such as survey and marketing providers, collect Personal Information and often share some or all of this information with us.'
  },
  {
    className: 'sub-text',
    text:
      '- Information Providers: We may from time to time obtain information from third-party information providers to correct or supplement Personal Information we collect. For example, we may obtain updated contact information from third-party information providers to reconnect with an individual. '
  },
  {
    className: 'sub-text',
    text: `- Job Applicant-Related Third Parties: If you are applying for a position at ${SITE_DATA.appName}, we may receive personal information from job applicant-related third parties, such as job application platforms, your current employer, references, background check providers and recruiters.`
  },
  {
    className: 'sub-text',
    text:
      '- Bank Partners and External Bank Account Information Providers. When you use certain Services, we may collect from you usernames, passwords, account numbers, and other account information for third-party websites and internet banking services. This information is used to obtain your account, transaction, and other banking information from the relevant financial institution on your behalf in order to display the information to you or to fulfill your requests for certain products, services, or transactions through a Service. By using our Services, you grant us and our service providers the right, power, and authority to act on your behalf to access and transmit this information from the relevant financial institution. '
  },
  {
    className: 'sub-text',
    text:
      '- Credit Reporting Agencies. If you enroll in certain features of the Services, we may need to collect credit score and other credit history data from a credit bureau. We will notify you and obtain your authorization at the point of your enrollment of such features of the Services.'
  },
  {
    className: 'sub-text',
    text:
      '- Publicly Available Sources: We collect Personal Information about individuals that we do not otherwise have, such as contact information, employment-related information, and interest-in-services information, from publicly available sources. We may combine this information with the information we collect from an individual directly. We use this information to contact individuals, to send advertising or promotional materials or to personalize our Sites and to better understand the demographics of the individuals with whom we interact.'
  },
  {
    className: 'roman-list-three',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text:
      '<a id="privacy-policy#our-use" class="font-bold">Our Use of Personal Information</a>'
  },
  {
    className: '',
    text: 'We use Personal Information we collect to: '
  },
  {
    className: 'sub-text',
    text:
      '- Fulfill or meet the reason the information was provided, such as to fulfill our contractual obligations, to deliver the Sites you have requested and to process transactions;'
  },
  {
    className: 'sub-text',
    text: '- Manage our organization and its day-to-day operations; '
  },
  {
    className: 'sub-text',
    text:
      '- Communicate with individuals, including via email, social media and/or telephone calls;'
  },
  {
    className: 'sub-text',
    text:
      'W- Request individuals to complete surveys about our company, companies we partner with, and Services;'
  },
  {
    className: 'sub-text',
    text: '- Market our Sites to individuals, including through email;'
  },
  {
    className: 'sub-text',
    text:
      '- Administer, improve and personalize our Services, including by recognizing an individual and remembering their information when they return to our Service;'
  },
  {
    className: 'sub-text',
    text:
      '- Facilitate customer benefits and services, including customer support through our command center services; '
  },
  {
    className: 'sub-text',
    text: '- Identify and analyze how individuals use our Services;'
  },
  {
    className: 'sub-text',
    text:
      '- Conduct research and analytics on our customer base and our Services;'
  },
  {
    className: 'sub-text',
    text:
      '- Improve and customize our Services to address the needs and interests of our customer base and other individuals we interact with;'
  },
  {
    className: 'sub-text',
    text:
      '- Evaluate your candidacy for employment, communicate with you during the job application process, and facilitate the onboarding process;'
  },
  {
    className: 'sub-text',
    text:
      '- Test, enhance, update and monitor the Services, or diagnose or fix technology problems;'
  },
  {
    className: 'sub-text',
    text:
      '- Help maintain the safety, security and integrity of our property and Services, technology assets and business;'
  },
  {
    className: 'sub-text',
    text:
      '- Defend, protect or enforce our rights or applicable contracts and agreements;'
  },
  {
    className: 'sub-text',
    text:
      '- Prevent, investigate or provide notice of fraud or unlawful or criminal activity; and'
  },
  {
    className: 'sub-text',
    text: '- Comply with legal obligations'
  },
  {
    className: 'sub-text',
    text:
      '- If you request access to your consumer credit reports, we may use it (1) to provide you with access to your free credit score, including periodic updates to your credit score, and (2) to make recommendations regarding financial products and services that may be of interest to you.'
  },
  {
    className: '',
    text:
      'Where an individual chooses to contact us, we may need additional information to fulfill the request or respond to inquiries. We may provide additional privacy disclosures where the scope of the inquiry/request and/or Personal Information we require fall outside the scope of this Privacy Notice. In that case, the additional privacy disclosures will govern how we may process the information provided at that time. '
  },
  {
    className: '',
    text:
      'Collected personal information may also be aggregated and/or de-identified (we remove any details that identify you personally). We may use this information for our own business or commercial purposes where permissible under applicable laws and regulations.'
  },
  {
    className: 'roman-list-four',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text:
      '<a id="privacy-policy#our-disclosure" class="font-bold">Our Disclosure of Personal Information</a>'
  },
  {
    className: '',
    text: 'We disclose Personal Information in the following ways: '
  },
  {
    className: 'sub-text',
    text: `- Affiliates. We may share Personal Information with other companies owned or controlled by ${SITE_DATA.appName}, and other companies owned by or under common ownership as ${SITE_DATA.appName}, which also includes our subsidiaries (i.e., any organization we own or control) or our ultimate holding company (i.e., any organization that owns or controls us) and any subsidiaries it owns, particularly when we collaborate in providing the Sites.`
  },
  {
    className: 'sub-text',
    text:
      '- Your Employer / Company: If you engage in our Services through your employer, or company, we disclose your information to the company such as name and contact information.'
  },
  {
    className: 'sub-text',
    text: `- Business Partners: We may share your personal information, ${
      SITE_DATA.appName === 'DogeCard' ? 'except personal phone number' : ''
    }, as part of joint marketing campaigns with partner financial companies, or with other financial services firms or non-affiliated third parties for their marketing purposes as permitted by applicable law. We also share your information with our financial institution partners in order for us to provide you Services or for such partners to provide their services in connection with our Services.`
  },
  {
    className: 'sub-text',
    text:
      '- Funding Servicing Partners: Funding servicing partners, who might process your payments, and service your accounts or loans.'
  },
  {
    className: 'sub-text',
    text:
      '- Financial institutions and credit reporting agencies: as required by laws regulating loan securitization, or in the provision of our Services. '
  },
  {
    className: 'sub-text',
    text:
      '- Survey Providers: We share Personal Information with third parties who assist us in delivering our survey offerings and processing the responses. '
  },
  {
    className: 'sub-text',
    text:
      '- Marketing Providers: We coordinate and share Personal Information with our marketing providers in order to communicate with individuals about the Sites we make available. '
  },
  {
    className: 'sub-text',
    text:
      '- Customer Service and Communication Providers: We share Personal Information with third parties who assist us in providing our customer services and facilitating our communications with individuals that submit inquiries. '
  },
  {
    className: 'sub-text',
    text: `- Other Service Providers: In addition to the third parties identified above, we engage other third-party service providers that perform business or operational services for us or on our behalf, such as website hosting, mobile application development, infrastructure provisioning, IT services, analytics services, administrative services, background checks and recruiting. When ${SITE_DATA.appName} shares your personal information with vendors and service providers who perform functions on our behalf, we require the security and confidentiality of your information, as well as limiting their use of the information to reasonably and necessarily to carry out their work with us and comply with applicable laws and regulations.`
  },
  {
    className: 'sub-text',
    text:
      '- Business Transaction or Reorganization: We may take part in or be involved with a corporate business transaction, such as a merger, acquisition, joint venture, or financing or sale of company assets. We may disclose Personal Information to a third party during negotiation of, in connection with or as an asset in such a corporate business transaction. Personal Information may also be disclosed in the event of insolvency, bankruptcy or receivership.  '
  },
  {
    className: 'sub-text',
    text:
      '- Legal Obligations and Rights: We may disclose Personal Information to third parties, such as legal advisors and law enforcement: '
  },
  {
    className: 'sub-bullet-dot',
    styleClassName: 'sub-bullet-style',
    text:
      'In connection with the establishment, exercise, or defense of legal claims;'
  },
  {
    className: 'sub-bullet-dot',
    styleClassName: 'sub-bullet-style',
    text:
      'To comply with laws or to respond to lawful requests and legal process;'
  },
  {
    className: 'sub-bullet-dot',
    styleClassName: 'sub-bullet-style',
    text:
      'To protect our rights and property and the rights and property of others, including to enforce our agreements and policies;'
  },
  {
    className: 'sub-bullet-dot',
    styleClassName: 'sub-bullet-style',
    text: 'To detect, suppress, or prevent fraud;'
  },
  {
    className: 'sub-bullet-dot',
    styleClassName: 'sub-bullet-style',
    text: 'To protect the health and safety of us and others; or'
  },
  {
    className: 'sub-bullet-dot',
    styleClassName: 'sub-bullet-style',
    text: 'As otherwise required by applicable law.'
  },
  {
    className: 'sub-text',
    text:
      '- Otherwise with Consent or Direction: We may disclose Personal Information about an individual to certain other third parties or publicly with their consent or direction. '
  },
  {
    className: 'roman-list-five',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text:
      '<a id="privacy-policy#control-over" class="font-bold"> Control Over Your Information</a>'
  },
  {
    className: 'underline-text',
    text: 'Updating Your Information'
  },
  {
    className: '',
    text:
      'Once you have registered for an account, you may update or correct your profile information and preferences at any time by accessing your account settings page through the app.'
  },
  {
    className: 'underline-text',
    text: 'Deactivating Your Account'
  },
  {
    className: '',
    text: `We do not delete information about you upon deactivation of your account. Although your deactivated status is reflected promptly in our user databases, we may retain the information you submit for a variety of purposes, including legal compliance, backups and archiving, prevention of fraud and abuse, and analytics.  Upon deactivation, you will no longer receive emails from ${SITE_DATA.appName} and links to third-party financial accounts and services will automatically terminate. If you have a money transfer transaction pending at the time you deactivate your account, your link to this service from ${SITE_DATA.appName} will terminate but your pending transfer will continue to completion and your account with your employer will remain open.`
  },
  {
    className: 'underline-text',
    text: 'Unsubscribing from Emails'
  },
  {
    className: '',
    text: `California's “Shine the Light” law (Civil Code Section §1798.83) provides certain rights to California residents that have an established business relationship with us with regard to the disclosure of certain types of personal information to third parties for their direct marketing purposes. To opt-out of having your personal information disclosed to third parties for their direct marketing purposes, please contact us at <a href=mailto:dataprotection@${SITE_DATA.domainMailto} class="cursor-pointer text-highlight">dataprotection@${SITE_DATA.domainMailto}</a> and provide your contact information to be added to our suppression list.`
  },
  {
    className: 'underline-text',
    text: 'Nevada Opt-Out Right '
  },
  {
    className: '',
    text: `If you are a resident of the State of Nevada, Chapter 603A of the Nevada Revised Statutes permits a Nevada resident to opt out of future sales of certain covered information that a website operator has collected or will collect about the resident. Although we do not currently sell covered information, please contact us at <a href=mailto:dataprotection@${SITE_DATA.domainMailto} class="cursor-pointer text-highlight">dataprotection@${SITE_DATA.domainMailto}</a> to submit such a request.`
  },
  {
    className: 'underline-text',
    text: 'Correcting Credit Reports'
  },
  {
    className: '',
    text:
      'Information related to your creditworthiness is maintained by the credit bureaus. If you find that there is an error or you want to dispute the information found in your credit report, please contact the credit reporting bureaus.'
  },
  {
    className: 'sub-text',
    text:
      'Experian: <a class="cursor-pointer text-highlight" target="_blank" href="http://www.experian.com/">http://www.experian.com/</a>'
  },
  {
    className: 'sub-text',
    text:
      'Transunion: <a class="cursor-pointer text-highlight" target="_blank" href="https://dispute.transunion.com/">https://dispute.transunion.com/</a>'
  },
  {
    className: 'sub-text',
    text:
      'Equifax: <a class="cursor-pointer text-highlight" target="_blank" href="https://www.equifax.com/">https://www.equifax.com/</a>'
  },
  {
    className: 'roman-list-six',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text:
      '<a id="privacy-policy#third-party" class="font-bold">Third Party Tracking and Online Advertising</a>'
  },
  {
    className: '',
    text: `${SITE_DATA.appName} may participate in interest-based advertising and use third party advertising partners to collect information from visitors to the Sites for the purpose of displaying interest-based advertisements on other websites, apps or services, social networks, or on other devices you may use. Typically, though not always, these third party ad networks use cookies and tracking technologies to recognize your browser or device and to collect information about your visit to our Service to provide you customized content, advertising and commercial messages which may be more relevant to your interests, as well as to provide advertising-related services such as reporting, attribution, analytics and market research. `
  },
  {
    className: '',
    text:
      'To learn about interest-based advertising and how you may be able to opt-out of some of this advertising, you may wish to visit the Network Advertising Initiative’s online resources, at <a class="cursor-pointer text-highlight" target="_blank" href="http://www.networkadvertising.org/choices">http://www.networkadvertising.org/choices</a>, and/or the DAA’s resources at <a class="cursor-pointer text-highlight" target="_blank" href="https://optout.aboutads.info/">www.aboutads.info/choices</a>. You may also be able to limit interest-based advertising through the settings on your mobile device by selecting “limit ad tracking” (iOS) or “opt-out of interest-based ads” (Android).  You may also be able to opt-out of some – but not all – interest-based ads served by mobile ad networks by visiting http://youradchoices.com/appchoices and downloading the mobile AppChoices app.'
  },
  {
    className: '',
    text:
      'Please note that opting-out of receiving interest-based advertising through the NAI’s and DAA’s online resources will only opt-out a user from receiving interest-based ads on that specific browser or device, but the user may still receive interest-based ads on his or her other devices. You must perform the opt-out on each browser or device you use.'
  },
  {
    className: 'roman-list-seven',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text:
      '<a id="privacy-policy#our-commitment" class="font-bold">Our Commitment to Data Security<a/>'
  },
  {
    className: '',
    text:
      'We use certain physical, managerial, and technical safeguards that are designed to protect the integrity and security of your information. We cannot, however, ensure or warrant the security of any information you transmit to us through the Services or store on the Site, and you do so at your own risk.'
  },
  {
    className: 'roman-list-eight',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text:
      '<a id="privacy-policy#children-personal" class="font-bold">Children’s Personal Information<a/>'
  },
  {
    className: '',
    text:
      'Our Sites are not directed to, and we do not intend to, or knowingly, collect or solicit Personal Information from children under the age of 13. If an individual is under the age of 13, they should not use our Sites or otherwise provide us with any Personal Information either directly or by other means. If a child under the age of 13 has provided Personal Information to us, we encourage the child’s parent or guardian to contact us to request that we remove the Personal Information from our systems. If we learn that any Personal Information we collect has been provided by a child under the age of 13, we will promptly delete that Personal Information.'
  },
  {
    className: 'roman-list-nine',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text:
      '<a id="privacy-policy#services" class="font-bold">Links to Third-Party Websites or Services<a>'
  },
  {
    className: '',
    text:
      'Our Sites may include links to third-party websites, plug-ins and applications. Except where we post, link to or expressly adopt or refer to this Privacy Notice, this Privacy Notice does not apply to, and we are not responsible for, any Personal Information practices of third-party websites and online services or the practices of other third parties. To learn about the Personal Information practices of third parties, please visit their respective privacy notices.'
  },
  {
    className: 'roman-list-ten',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text:
      '<a id="privacy-policy#notice" class="font-bold">Updates to This Privacy Notice</a>'
  },
  {
    className: '',
    text:
      'We will update this Privacy Notice from time to time. When we make changes to this Privacy Notice, we will change the date at the beginning of this Privacy Notice. If we make material changes to this Privacy Notice, we will notify individuals by email to their registered email address, by prominent posting on our Sites, or through other appropriate communication channels. All changes shall be effective from the date of publication unless otherwise provided.'
  },
  {
    className: 'roman-list-elevent',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text: '<a id="privacy-policy#contact-us" class="font-bold">Contact Us</a>'
  },
  {
    className: '',
    text: `If you have any questions or requests in connection with this Privacy Notice or other privacy-related matters, please send an email to <a href=mailto:legal@${SITE_DATA.domainMailto} class="cursor-pointer text-highlight">legal@${SITE_DATA.domainMailto}</a>.`
  }
];
