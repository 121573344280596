export const ERROR_MESSAGES = {
  DEFAULT: 'Something goes wrong',
  INVALID_ACCOUNT: 'Invalid phone or email',
  INVALID_AUTH: `You don't have permission to access this page`,
  INVALID_VERIFICATION_CODE: `Incorrect code`,
  EMAIL_ALREADY_CONNECTED:
    'Email or phone is already registered to another user',
  COMMON: `We're sorry, there are error creating account`,
  INVALID_PHONE: 'Invalid phone',
  INVALID_EMAIL: 'Invalid email',
  INVALID_REQUEST: 'The request body or params are invalid'
};
