import React, { useState } from 'react';
import Image from 'next/image';

interface LazyLoadImage {
  src: string;
  alt: string;
  className?: string;
  onLoad?: () => void;
  onClick?: () => void;
  width?: number;
  height?: number;
  isCircle?: boolean;
}
const LazyLoadImage = ({
  src,
  alt,
  className = '',
  onLoad = () => {},
  onClick = () => {},
  width = 80,
  height = 80,
  ...props
}: LazyLoadImage) => {
  return (
    <Image
      alt={alt}
      src={src}
      width={width}
      height={height}
      placeholder="blur"
      blurDataURL={src}
      loading="lazy"
      className={className}
      onLoad={() => {
        onLoad && onLoad();
      }}
      onClick={onClick}
      {...props}
    />
  );
};

export default LazyLoadImage;
