import { SITE_DATA } from 'public/constants/common';
import { ROUTES } from '../../constants/routes';
import { LEGAL_STYLES } from 'public/constants/styles-constants';

export const DOGEKING_REFERRAL_TERMS = [
  {
    text: `${SITE_DATA.appName} Referral Program Rules`,
    key: 'item-term'
  },
  {
    className: '',
    text: `${SITE_DATA.appName}, its subsidiaries and affiliates (“${SITE_DATA.appName}," “we,” “our,” or “us”) may, from time to time, offer you the opportunity to earn rewards by referring friends to try the ${SITE_DATA.appName} services offered through our mobile application and/or website (the “Services”). Your participation in a ${SITE_DATA.appName} referral program (the “Program”), either by referring your friends or family or signing up with a referral code, can earn ${SITE_DATA.appName} Service users (collectively "${SITE_DATA.appName} users") ${SITE_DATA.firstAppName} rewards, and may also get you additional rewards.`
  },
  {
    className: '',
    text: `The ${SITE_DATA.appName} Referral Program Rules (the "Rules") apply to each Program that is administered solely by ${SITE_DATA.appName}. To participate in a Program, you must agree to these Rules, which are incorporated in and become part of the <a href="${ROUTES.LEGAL_PLATFORM_TERMS_PAGE_LINK}" class="cursor-pointer text-yellow text-highlight">Terms of Service</a>. By accepting these Rules, sharing your referral code or signing up with a referral code, you are agreeing to be bound by these Rules. Any violation of these Rules will not only prevent you from participating in any ${SITE_DATA.appName} Program (now or in the future), but could also result in the forfeiture or retraction of ${SITE_DATA.firstAppName} rewards, money, or other rewards earned through the Program and even the deactivation of your account.`
  },
  {
    className: 'title-scope',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text: 'Who is eligible to be a referrer/inviter?'
  },
  {
    className: '',
    text: `You may be a referrer/inviter if you (a) are a legal resident of the United States, (b) are of the age of majority where you live, (c) have a ${SITE_DATA.appName} account in good standing, and (d) are in compliance with our <a class="cursor-pointer text-highlight" href='${ROUTES.LEGAL_PAGE}'>Terms of Use</a>. Referrers/inviters cannot have more than one account for each ${SITE_DATA.appName} product or service. We, in our sole and absolute discretion, may determine if you meet the eligibility requirements to be a referrer/inviter, and may modify eligibility criteria in our sole discretion and at any time without notice to you.`
  },
  {
    className: 'dogeking-services',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text: 'Who is eligible to be a referee/invitee?'
  },
  {
    className: '',
    text: `Your friends, family, and other people you know (but not yourself) may be eligible to be referees/invitees, provided, such person: (a) is a new ${SITE_DATA.appName} user, (b) meets the conditions ${SITE_DATA.appName} has for using the Services and meets the eligibility criteria noted in the referral invite or offer, (c) completes the actions required by the specific referral Program and agrees to the <a class="cursor-pointer text-highlight" href='${ROUTES.LEGAL_PAGE}'>Terms of Use</a> and any other agreements required to establish an account or required by ${SITE_DATA.appName} (“Ancillary Agreements”), (d) signs up within 90 days of the referral invitation being sent, (e) uses your referral code or link when they sign up; (f) is of the age of majority where such person lives, and (g) is a legal resident of the United States. We, in our sole and absolute discretion, may determine if such person meets the eligibility requirements to be a referee/invitee, and may modify eligibility criteria in our sole discretion and at any time without notice to you.`
  },
  {
    className: '',
    text: `Your referees/invitees can be referred only once, so if someone else has referred them and they have accepted that invitation they will not be able to accept yours. If you are providing ${SITE_DATA.appName} with the contact information for your referees/invitees, you represent that you have the right to provide that information.`
  },
  {
    className: 'fees',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text: 'How can I use my referral code?'
  },
  {
    className: '',
    text: `${SITE_DATA.appName} has the right to limit the number of times you may use or share your referral code based on the Program in which you participate. To see if your code has a limit, refer to the terms of the specific Program in which you are participating.`
  },
  {
    className: '',
    text: `${SITE_DATA.appName} wants you to share your referral code and earn referral rewards, but referral codes must be used only for personal and non-commercial purposes. This means that you can share your referral code only with people you know. You agree that you will not:`
  },

  {
    className: 'title-scope',
    styleClassName: 'pl-10 sub-bullet-style',
    text:
      'Duplicate, sell, or transfer your referral code in any manner or make it available to the general public (such as by printing it on business cards; posting it on a coupon website, job website or using it as part of a job application, including, but not limited to, the following website and applications Amazon, EBay, Fiverr, Craigslist, RetailMeNot, Reddit, Wikipedia, Gumtree, Moneysavingexpert, Groupon or using paid social media or paid search);'
  },
  {
    className: 'dogeking-services',
    styleClassName: 'pl-10 sub-bullet-style',
    text:
      'Try to get referees/invitees by spamming, bulk emailing, or sending large numbers of unsolicited emails. The only people you should be emailing are people you know personally;'
  },
  {
    className: 'fees',
    styleClassName: 'pl-10 sub-bullet-style',
    text: `Use, display, or manipulate ${SITE_DATA.appName} intellectual property (such as ${SITE_DATA.appName}’s logos, trademarks, and copyright-protected works) in any way, except as to identify yourself as an ${SITE_DATA.appName} user, ${SITE_DATA.appName} referrer/inviter, or referrer/inviter for ${SITE_DATA.appName};`
  },
  {
    className: 'proprietary',
    styleClassName: 'pl-10 sub-bullet-style',
    text: `Create or register any (i) businesses, (ii) URLs, (iii) domain names, (iv) software application names or titles, or (v) social media handles or profiles that include the word "${SITE_DATA.appName}" or “${SITE_DATA.appName}” or any of ${SITE_DATA.appName}’s other trademarks or any words that are confusingly similar to ${SITE_DATA.appName}s trademarks.`
  },

  {
    className: 'site-data',
    styleClassName: 'pl-10 sub-bullet-style',
    text: `Use ${SITE_DATA.appName}’s trademarks as your social media profile picture or wallpaper or use any of ${SITE_DATA.appName}s copyright-protected works (such as graphics, photos, images, drawings, and screenshots from ${SITE_DATA.appName}s website or app) without ${SITE_DATA.appName}s express written permission;`
  },
  {
    className: 'third-party',
    styleClassName: 'pl-10 sub-bullet-style',
    text: `Purchase keywords (including, but not limited to Google AdWords) that contain any of ${SITE_DATA.appName}s trademarks;`
  },
  {
    className: 'indemnification',
    styleClassName: 'pl-10 sub-bullet-style',
    text:
      'Use automated systems or bots through any channel to distribute, post, or otherwise share your referral code;'
  },
  {
    className: 'warranty',
    styleClassName: 'pl-10 sub-bullet-style',
    text:
      'Use scripts or programmed or automatic dialers to send invites or otherwise share your referral code;'
  },

  {
    className: 'limitation',
    styleClassName: 'pl-10 sub-bullet-style',
    text: `Make misleading claims about ${SITE_DATA.appName}, use offensive/abusive content, create fake websites/webpages/social media profiles/apps, misrepresent your connection to ${SITE_DATA.appName}, or otherwise make any false or misleading statements to get a referee/invitee to use your code; or`
  },
  {
    className: 'termination',
    styleClassName: 'pl-10 pb-5 sub-bullet-style',
    text:
      'Use your referral code in any manner that violates the law or the rights of anyone else.'
  },
  {
    className: '',
    text: `Remember, when you share your referral code, you should explain that you are a ${SITE_DATA.appName} referrer.`
  },
  {
    className: '',
    text: `From time to time, you may be offered special promotions, and special referral codes to use. These special promotions may be offered to some, but not all ${SITE_DATA.appName} users, based on ${SITE_DATA.appName}’s sole and absolute discretion. Such special promotions are for a limited time and will give you additional benefits and rewards. Additional terms, including expiration dates for any special promotion will be provided with the special promotion. During special promotions, you may use your special referral code, and will receive rewards based upon its use. During any special promotion, you will only receive the identified reward outlined special promotion only if the referee/invitee completes the task prior to the end date of the special promotion period using the special referral code (and so long as the referee/invitee meets the eligibility criteria and any other terms of these Rules).`
  },
  {
    className: 'proprietary',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text: 'How can I use my referral code?'
  },
  {
    className: '',
    text: `As long as you and your referee/invitee comply with these Rules, the <a class="cursor-pointer text-highlight" href='${ROUTES.LEGAL_PAGE}'>Terms of Use</a> and the Ancillary Agreement, and you have an activated account, you should receive your referral reward after your referee/invitee uses your code to sign up with ${SITE_DATA.appName} and/or to activate the referral offer, and completes the requirements for the particular referral reward, subject to the eligibility criteria set forth herein.`
  },
  {
    className: '',
    text: `Note that unless stated otherwise on the referral offer, all referral invitations will expire 90 days from send date and all referral rewards will expire 90 days after the date that the invitee signs up as a ${SITE_DATA.appName} user or 90 days after the invitee signs up.`
  },
  {
    className: '',
    text: `Referrers are limited to a total of 10 referral payouts. Referral rewards are discretionary and the amount of a referral reward and the requirements to get it can vary, all in the sole discretion of ${SITE_DATA.appName}. ${SITE_DATA.appName} reserves the right to set a limit on the number of times you may use your referral code. The requirements for receiving, and the amounts of, referral rewards are subject to change at ${SITE_DATA.appName}’s sole discretion. Referral rewards in the form of ${SITE_DATA.firstAppName} rewards are not transferable, have no cash value, and may expire. The amount and form of the ${SITE_DATA.firstAppName} reward will be shown in their invitation.`
  },
  {
    className: 'site-data',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text: 'How can I earn a referral reward as a referee/invitee?'
  },
  {
    className: '',
    text: `Referee/invitee may get ${SITE_DATA.firstAppName} reward the first time such person uses the Services, so long as they are a ${SITE_DATA.appName} user and consents to and complies with these Rules, the <a class="cursor-pointer text-highlight" href='${ROUTES.LEGAL_PAGE}'>Terms of Use</a> and the Ancillary Agreement. The amount and form of the ${SITE_DATA.firstAppName} reward will be shown in their invitation.
    `
  },
  {
    className: 'third-party',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text: 'Miscellaneous Terms.'
  },
  {
    className: '',
    text: `Participation in the Program may require you and/or your referee/invitee to submit personal information about themselves. The personal information will be collected, processed and used in accordance with our privacy policy, which can be found at <a class="cursor-pointer text-highlight" href='${ROUTES.LEGAL_SOLID_PRIVACY_PAGE_LINK}'>${SITE_DATA.domain}legal#solid-privacy</a>. In addition, personal information may be used by us to contact ${SITE_DATA.appName} users with regards to participation in the Program and to receive communications from us or third party administrators of the Program.`
  },
  {
    className: '',
    text: `This Program is void where such referral programs are prohibited. If any provision in these Rules are held to be invalid, void, or unenforceable, such provision (or the part of it that is making it invalid, void or unenforceable) will be struck and not affect the validity of and enforceability of the remaining provisions.`
  },
  {
    className: '',
    text: `Any person that participates in the Program, including you and your referees/invitees, agree release and hold harmless ${SITE_DATA.appName} from any and all claims, demands, damages, losses, liabilities, costs or expenses caused by, arising out of, in connection with, or related to their participation in the Program (including, without limitation, any property loss, damage, personal injury or death caused to any person(s) and/or the awarding, receipt and/or use or misuse of the Program or any referral rewards).`
  },
  {
    className: '',
    text: `YOU, INVITEES/REFEREES, <span class="upppercase">${SITE_DATA.appName}</span> USERS AND PROSPECTIVE DOGEKING USERS EXPRESSLY UNDERSTAND AND AGREE THAT: (A) THE USE OF THE PROGRAM IS AT SUCH PARTY’S SOLE RISK, THE PROGRAM IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS AND WE EXPRESSLY DISCLAIMS ALL WARRANTIES, CONDITIONS AND TERMS (COLLECTIVELY, "PROMISES") OF ANY KIND, WHETHER EXPRESS OR IMPLIED BY STATUTE, COMMON LAW OR CUSTOM, INCLUDING, BUT NOT LIMITED TO, PROMISES AS TO PRODUCTS OR SERVICES OFFERED THROUGH THE USE OF THE PROGRAM, IMPLIED PROMISES OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT; (B) WE MAKE AND GIVE NO PROMISE THAT (i) THE PROGRAM WILL MEET SUCH PARTY’S REQUIREMENTS, (ii) BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS OBTAINED FROM THE USE OF THE PROGRAM WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL OBTAINED BY YOU THROUGH THE PROGRAM WILL MEET SUCH PARTY’S EXPECTATIONS, AND (v) ANY ERRORS IN THE SERVICE OR PROGRAM WILL BE CORRECTED; AND (C) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE PROGRAM IS ACCESSED AT SUCH PARTY’S OWN DISCRETION AND RISK, AND SUCH PARTY WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO HIS/HER COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF ANY SUCH MATERIAL.`
  },
  {
    className: 'indemnification',
    colorClassName: `${LEGAL_STYLES.textContent}`,
    styleClassName: 'font-bold',
    text: 'Termination and changes'
  },
  {
    className: '',
    text: `${SITE_DATA.appName} reserves the right to change, end, or pause, in whole or in part, any Program, as well as any referrer/inviter's or referee/invitee's ability to participate in any Program or receive referral rewards at any time for any reason, including suspected fraud (including by either the referrer and/or referee/invitee), abuse, or any violation of these Rules, the <a class="cursor-pointer text-highlight" href='${ROUTES.LEGAL_PAGE}'>Terms of Use</a> or the Ancillary Agreement, as well as any agreement (if one exists) between you and ${SITE_DATA.appName} or any of its business partners. If ${SITE_DATA.appName} ends any Program, any unused or unredeemed referral rewards may be forfeited at that time, provided we give you 14 days of notice to use or redeem your referral rewards.
    ${SITE_DATA.appName} may update these Rules at any time. If ${SITE_DATA.appName} makes an update, ${SITE_DATA.appName} will post the update on the <a class="cursor-pointer text-highlight" href='${ROUTES.HOME}'>${SITE_DATA.domain}</a> website and applications and provide you notice of the update. Continued participation in any Program after any update will mean that you have agreed to the update.`
  }
];
