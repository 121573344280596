export const DOGEKING_DEAL_REWARDS = [
  {
    className: '',
    text:
      'You can earn Solid Fintech Program Rewards powered by Dosh® on qualifying purchases at participating merchants and on any other eligible transactions described in the Fintech Program User Agreement. For the current list of participating merchants and offers, please check the Fintech Program app. Participating merchants and offer terms may be changed from time to time without notice.'
  },
  {
    className: '',
    text:
      'Solid Fintech Program Rewards will be paid out to your Fintech Program Account.'
  },
  {
    className: '',
    text:
      'If you return, charge back, cancel, dispute, or otherwise request a refund for a qualifying purchase for which you have already received Solid Fintech Program Rewards, we reserve the right to remove any related Solid Fintech Program Rewards amount from your Fintech Program Account balance or withhold future Solid Fintech Program Rewards to cover any such amount. To opt-out of the Solid Fintech Program Rewards program, please contact customer support.'
  },
  {
    className: '',
    text: `For full Solid Fintech Program Rewards terms, please review the Solid Fintech Program Rewards - <a href="https://help.solidfi.com/hc/en-us/articles/9196440416539" target="_blank" class="cursor-pointer text-yellow text-highlight">Card Rewards Terms - Powered by Dosh</a>`
  }
];
