export const CRM_EVENT = {
  LOGGED_IN: 'logged in',
  SIGNED_UP: 'signed up',
  VISIT: 'visit',
  CLICK_SHARE_BUTTON: 'click share button',
  USER_REFERRAL: 'User Referral',
  HOME_KEY: 'landing page',
  LEGAL_KEY: 'legal page',
  CHOOSE_CARD_MODAL_KEY: 'choose card modal',
  REFERRAL_KEY: 'referral page',
  HOME_DESCRIPTION: 'Visited the landing page',
  LEGAL_DESCRIPTION: 'Visited the legal page',
  CHOOSE_CARD_DESCRIPTION: 'Shown the choose card modal',
  REFERRAL_DESCRIPTION: 'Visited the referral page',
  FACEBOOK_DESCRIPTION: 'User clicks on facebook button',
  TWITTER_DESCRIPTION: 'User clicks on twitter button',
  WHATSAPP_DESCRIPTION: 'User clicks on whatsapp button',
  REFERRAL_SIGN_UP_DESCRIPTION: 'Signed up with a referral code',
  ORGANIC_SIGN_UP_DESCRIPTION: 'No referral code'
};
